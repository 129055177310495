import React, { useState, useContext, useCallback, useEffect } from "react";
import { Formik, FieldArray, ErrorMessage } from "formik";
import { HexColorPicker } from "react-colorful";

import { Accordion, Modal } from "react-bootstrap";

import { useHistory, useParams } from "react-router-dom";

import * as Yup from "yup";
import { setLocale } from "yup";

import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/AuthContext";

import PreviewModal from "../components/PreviewModal";
import TemplatesModal from "../components/TemplatesModal";

import BannerStands from '../assets/Banner5.png';
import AccordionStandCardEdition from "../components/cards/AccordionStandCardEdition";
import CarouselStandCardEdition from "../components/cards/CarouselStandCardEdition";
import CustomQuestionsCardEdition from "../components/cards/CustomQuestionsCardEdition";
import DefaultStandCardEdition from "../components/cards/DefaultStandCardEdition";
import EcommerceStandCardEdition from "../components/cards/EcommerceStandCardEdition";
import ImageStandCardEdition from "../components/cards/ImageStandCardEdition";
import MapStandCardEdition from "../components/cards/MapStandCardEdition";
import NumberTitleTextStandCardEdition from "../components/cards/NumberTitleTextStandCardEdition";
import ReducedStandCardEdition from "../components/cards/ReducedStandCardEdition";
import TitleStandCardEdition from "../components/cards/TitleStandCardEdition";
import TitleTextStandCardEdition from "../components/cards/TitleTextStandCardEdition";
import VideoStandCardEdition from "../components/cards/VideoStandCardEdition";

import { IoCloseSharp } from "react-icons/io5";
import { AiFillEdit } from "react-icons/ai";

import { BsCardChecklist , BsFillInfoCircleFill , BsArrowUp, BsArrowDown, BsFillArrowLeftCircleFill, BsFillEyeFill , BsFillPlusCircleFill , BsFillDashCircleFill , BsFillSaveFill } from "react-icons/bs";

import Button from "../../../shared/components/FormElements/Button";
import {
    GenericForm,
    FormInputComponent,
} from "../../../shared/components/FormElements/GenericForm";

import "./UpdateStand.css";
import mapimage from "../assets/mapimage.png";

const SIMPLESTAND_PLANS = [
    {
        value: "standard",
        text: "Standard (5)",
        cardsLimit: 5,
    },
    {
        value: "standardPlus",
        text: "Standard Plus (10)",
        cardsLimit: 10,
    },
    {
        value: "premium",
        text: "Premium (15)",
        cardsLimit: 15,
    },

    {
        value: "unlimited",
        text: "Ilimitado",
        cardsLimit: 20,
    },
];

let HEXCOLOR_REGEXP =
    /#[a-f\d]{3}(?:[a-f\d]?|(?:[a-f\d]{3}(?:[a-f\d]{2})?)?)\b/;

setLocale({
    mixed: {
        // eslint-disable-next-line
        notType: "${path} is required",
    },
});
let PHONE_REGEXP =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const UpdateStand = () => {
    const auth = useContext(AuthContext);
    const { sendRequest, error, clearError, isLoading } = useHttpClient();
    const standName = useParams().standName;

    const [stand, setStand] = useState(null);
    const [standNameEdition, setStandNameEdition] = useState(false);

    const [colorPickerVariable, setColorPickerVariable] = useState(null);
    const [colorPickerField, setColorPickerField] = useState(null);

    const [stateStandPlan, setStateStandPlan] = useState(SIMPLESTAND_PLANS[0]);
    const [showModal, setShowModal] = useState(false);
    const [adminUsers, setAdminUsers] = useState([]);
    const [exhibitorUsers, setExhibitorUsers] = useState([]);
    const [variableFormFields, setVariableFormFields] = useState([]);
    const [checked, setChecked] = useState("");

    const handleClose = () => setShowModal(false);
    const handleShowNameEdition = () => setStandNameEdition(!standNameEdition);

    const [showPreview, setShowPreview] = useState(false);

    const handleShowPreview = () => setShowPreview(true);
    const handleClosePreview = () => setShowPreview(false);

    const [showTemplates, setShowTemplates] = useState(false);
    const handleShowTemplates = () => setShowTemplates(true);
    const handleCloseTemplates = () => setShowTemplates(false);

    const history = useHistory();

    const getAdminUsers = useCallback(async () => {
        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_API_URL}/api/users/admin`
            );
            setAdminUsers(responseData.adminusers);
        } catch (err) {}
    }, [sendRequest]);

    let initialValues;

    function setInitialValuesFormFields(value) {
        const found = stand.formFields.find(
            (c) => c._id === variableFormFields[value]._id
        );

        if (found) {
            initialValues = found._id;
        } else {
            initialValues = 0;
        }

        return initialValues;
    }

    function getValuesFormFields() {
        let allFormFields = document
            .getElementById("formFieldsValueUpdate")
            .querySelectorAll(".generic-form__form-input");
        let valueArray = [];

        allFormFields.forEach((element) => {
            if (element.value !== "0") {
                valueArray.push(element.value);
            }
        });

        return valueArray;
    }

    const getExhibitorUsers = useCallback(async () => {
        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_API_URL}/api/users/exhibitor`
            );
            setExhibitorUsers(responseData.exhibitorusers);
        } catch (err) {}
    }, [sendRequest]);

    const standFetch = useCallback(async () => {
        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_API_URL}/api/qrs/edit/${standName}`,
                "GET",
                undefined,
                { Authorization: "Bearer " + auth.token }
            );

            setStand(responseData.stand);

            let plan = SIMPLESTAND_PLANS.find(
                (o) => o.value === responseData.stand.standPlan
            );
            setStateStandPlan(plan);
        } catch (err) {}
    }, [sendRequest, standName, auth.token]);

    const formFieldsFetch = useCallback(async () => {
        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_API_URL}/api/formfields/`
            );

            responseData.formFields[0].fieldSelect = "Si";
            setVariableFormFields(responseData.formFields);
        } catch (err) {}
    }, [sendRequest]);

    useEffect(() => {
        standFetch();
    }, [standFetch]);

    useEffect(() => {
        getAdminUsers();
    }, [getAdminUsers]);

    useEffect(() => {
        getExhibitorUsers();
    }, [getExhibitorUsers]);

    useEffect(() => {
        formFieldsFetch();
    }, [formFieldsFetch]);

    const showHexPickerHandler = (variable, field) => {
        setShowModal(true);
        setColorPickerVariable(variable);
        setColorPickerField(field);
    };

    let swapHandler = (swap, index, destination, size) => {
        if (destination >= 0 && destination < size) {
            swap(index, destination);
        }
    };

    let planHandler = (e) => {
        let plan = SIMPLESTAND_PLANS.find((o) => o.value === e);
        setStateStandPlan(plan);
    };

    const makeConditionalSchema = (cardsLimit) => {
        let HelpSchema = Yup.object().shape({
            mainBanner: Yup.mixed().required("Banner requerido"),
            navIcon: Yup.mixed(),
            hexColor: Yup.string()
                .required("Campo requerido")
                .matches(
                    HEXCOLOR_REGEXP,
                    "Por favor sólo utilizar un valor hexadecimal para el color"
                ),
            navColor: Yup.string()
                .required("Campo requerido")
                .matches(
                    HEXCOLOR_REGEXP,
                    "Por favor sólo utilizar un valor hexadecimal para el color"
                ),
            navButtonsColor: Yup.string()
                .required("Campo requerido")
                .matches(
                    HEXCOLOR_REGEXP,
                    "Por favor sólo utilizar un valor hexadecimal para el color"
                ),
            textColor: Yup.string()
                .required("Campo requerido")
                .matches(
                    HEXCOLOR_REGEXP,
                    "Por favor sólo utilizar un valor hexadecimal para el color"
                ),
            selectedFont: Yup.string().required("Campo requerido"),
            active: Yup.string().required("Campo requerido"),
            requiresLogin: Yup.boolean().required("Campo requerido"),
            emailButtonLink: Yup.string().email("Correo inválido"),
            whatsappButtonLink: Yup.string().matches(
                PHONE_REGEXP,
                "Teléfono inválido"
            ),
            // networks: Yup.object().shape({
            //     type: Yup.string().required(),
            //     link: Yup.string().required(),
            // }),
            cards: Yup.array().max(
                cardsLimit,
                "Está superando la capacidad máxima de cards en su plan"
            ),
        });

        return HelpSchema;
    };


    //AGREGAR CARDS ACÁ
    const cardTypes = {
        AccordionStandCard: {
            editionComponent: AccordionStandCardEdition,
            value: "AccordionStandCard",
            text: "Acordeón",
        },
        // CarouselStandCard: {
        //     editionComponent: CarouselStandCardEdition,
        //     value: "CarouselStandCard",
        //     text: "Carrusel de Imágenes",
        // },
        CustomQuestionsCard: {
            editionComponent: CustomQuestionsCardEdition,
            value: "CustomQuestionsCard",
            text: "Encuesta de Satisfaccion",
        },
        DefaultStandCard: {
            editionComponent: DefaultStandCardEdition,
            value: "DefaultStandCard",
            text: "Título, Texto e Imagen",
        },
        EcommerceStandCard: {
            editionComponent: EcommerceStandCardEdition,
            value: "EcommerceStandCard",
            text: "Ecommerce",
        },
        ImageStandCard: {
            editionComponent: ImageStandCardEdition,
            value: "ImageStandCard",
            text: "Imagen Sola",
        },
        MapStandCard: {
            editionComponent: MapStandCardEdition,
            value: "MapStandCard",
            text: "Mapa",
        },
        NumberTitleTextStandCard: {
            editionComponent: NumberTitleTextStandCardEdition,
            value: "NumberTitleTextStandCard",
            text: "Número, título y descripción",
        },
        ReducedStandCard: {
            editionComponent: ReducedStandCardEdition,
            value: "ReducedStandCard",
            text: "Imagen, Título y Descripción",
        },
        TitleStandCard: {
            editionComponent: TitleStandCardEdition,
            value: "TitleStandCard",
            text: "Título Solo",
        },
        TitleTextStandCard: {
            editionComponent: TitleTextStandCardEdition,
            value: "TitleTextStandCard",
            text: "Título y Texto",
        },
        VideoStandCard: {
            editionComponent: VideoStandCardEdition,
            value: "VideoStandCard",
            text: "Video",
        },
    };

    const CardPicker = (props) => {
        let CardType = cardTypes[props.card.cardType].editionComponent;

        return (
            <CardType
                key={props.index}
                index={props.index}
                showHexPickerHandler={props.showHexPickerHandler}
                setFieldValue={props.setFieldValue}
                card={props.card}
            />
        );
    };

    if (!isLoading && stand && variableFormFields) {
        return (
            <React.Fragment>
            <img src={BannerStands} width="100%" alt="" />
            <div className="generic-form__button-block"> 
                                <Button
                                    to="/backend/qrs"
                                    className="stands-list__back-button"
                                >
                                    <BsFillArrowLeftCircleFill className="standlist-imageButton__iconblack" />
                                    Atrás
                                </Button>
            </div>
                <Formik
                    initialValues={{
                        standName: stand.standName,
                        standPlan: stand.standPlan,
                        requiresLogin: stand.requiresLogin,
                        uniqueLikeOnStand: stand.uniqueLikeOnStand,
                        mainBanner: stand.mainBanner,
                        hexColor: stand.hexColor,
                        navColor: stand.navColor,
                        navButtonsColor: stand.navButtonsColor,
                        navIcon: stand.navIcon,
                        textColor: stand.textColor,
                        emailButtonLink: stand.emailButtonLink,
                        emailButtonText: stand.emailButtonText,
                        whatsappButtonLink: stand.whatsappButtonLink,
                        whatsappButtonText: stand.whatsappButtonText,
                        active: stand.active,
                        networks: stand.networks,
                        selectedFont: stand.selectedFont,
                        cards: stand.cards,
                        formFields: [],
                        customFormFields: stand.customFormFields,
                        customFormFieldsOnCard: true,
                        owner: stand.owner,
                        canEdit: stand.canEdit,
                    }}
                    validateOnMount
                    validationSchema={makeConditionalSchema(
                        stateStandPlan.cardsLimit
                    )}
                    onSubmit={async (values) => {
                        clearError();

                        try {
                            const formData = new FormData();

                            formData.append("standName", values.standName);
                            formData.append("standPlan", values.standPlan);

                            if (auth.loggedUserData.userType === "superadmin") {
                                if (values.owner) {
                                    formData.append("owner", values.owner);
                                } else {
                                    formData.append("owner", auth.userId);
                                }
                            }
                            formData.append(
                                "uniqueLikeOnStand",
                                values.uniqueLikeOnStand
                            );

                            if (
                                auth.loggedUserData.userType === "superadmin" ||
                                auth.loggedUserData.userType === "admin"
                            ) {
                                formData.append("canEdit", values.canEdit);
                            }

                            formData.append(
                                "requiresLogin",
                                values.requiresLogin
                            );
                            formData.append("mainBanner", values.mainBanner);
                            formData.append("hexColor", values.hexColor);
                            formData.append("navColor", values.navColor);
                            formData.append(
                                "navButtonsColor",
                                values.navButtonsColor
                            );
                            formData.append("navIcon", values.navIcon);
                            formData.append("textColor", values.textColor);
                            formData.append(
                                "selectedFont",
                                values.selectedFont
                            );
                            formData.append(
                                "emailButtonLink",
                                values.emailButtonLink
                            );
                            formData.append(
                                "emailButtonText",
                                values.emailButtonText
                            );
                            formData.append(
                                "whatsappButtonLink",
                                values.whatsappButtonLink
                            );
                            formData.append(
                                "whatsappButtonText",
                                values.whatsappButtonText
                            );
                            formData.append("active", values.active);

                            values.cards.forEach((item) => {
                                formData.append(
                                    `cards[]`,
                                    JSON.stringify(item)
                                );
                            });

                            values.networks.forEach((item) => {
                                formData.append(
                                    `networks[]`,
                                    JSON.stringify(item)
                                );
                            });

                            let valuesFormFields = getValuesFormFields();

                            if (valuesFormFields.length > 0) {
                                valuesFormFields.forEach((formField) => {
                                    formData.append(`formFields[]`, formField);
                                });
                            }

                            values.customFormFields.forEach(
                                (customFormField) => {
                                    formData.append(
                                        `customFormFields[]`,
                                        JSON.stringify(customFormField)
                                    );
                                }
                            );

                            formData.append("customFormFieldsOnCard", true);

                            values.cards.forEach((card, index) => {
                                card.image &&
                                    formData.append(
                                        `standCard${index}`,
                                        card.image
                                    );

                                if (card.imageArray) {
                                    if (card.imageArray.length > 0) {
                                        for (
                                            let i = 0;
                                            i < card.imageArray.length;
                                            i++
                                        ) {
                                            formData.append(
                                                `standCard${index}.imageArray${i}`,
                                                card.imageArray[i]
                                            );
                                        }
                                    }
                                }
                            });

                            await sendRequest(
                                `${process.env.REACT_APP_API_URL}/api/qrs/${stand._id}`,
                                "PATCH",
                                formData,
                                { Authorization: "Bearer " + auth.token }
                            );
                            if (values.active === "Eliminado") {
                                history.push(`../../backend/qrs`);
                            } else {
                                history.push(`/qrs/${values.standName}`);
                            }
                        } catch (err) {}
                    }}
                >
                    {({ values, setFieldValue }) => (
                        <React.Fragment>
                            <Modal show={showModal} onHide={handleClose}>
                                <Modal.Header>
                                    <button
                                        onClick={handleClose}
                                        className="generic-modal__close"
                                    >
                                        <IoCloseSharp />
                                    </button>
                                    <Modal.Title>Selector de Color</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <HexColorPicker
                                        color={colorPickerVariable}
                                        onChange={(color) =>
                                            setFieldValue(
                                                colorPickerField,
                                                color
                                            ) && setColorPickerVariable(color)
                                        }
                                    />
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        className=""
                                        onClick={handleClose}
                                    >
                                    <BsFillArrowLeftCircleFill className="standlist-imageButton__iconblack" />
                                        Cerrar
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <TemplatesModal
                                show={showTemplates}
                                onClose={handleCloseTemplates}
                            />
                            <PreviewModal
                                show={showPreview}
                                onClose={handleClosePreview}
                                stand={values}
                                editPreview={true}
                            />

                            <Button
                                className="preview-stand-button"
                                onClick={handleShowPreview}
                            >
                             <BsFillEyeFill className="standlist-imageButton__iconblack" />
                                Mostrar Preview
                            </Button>
                            <GenericForm className="update-stand-form" error={error}>
                           
                                <div className="update-stand-title">
                                    <div>{stand.standName}</div>
                                    {auth.loggedUserData.userType ===
                                        "superadmin" ||
                                        (auth.loggedUserData.userType ===
                                            "admin" && (
                                            <button
                                                className="update-stand__name-edition-button"
                                                type="button"
                                                onClick={handleShowNameEdition}
                                            >
                                                <AiFillEdit />
                                            </button>
                                        ))}
                                </div>
                                {standNameEdition && (
                                    <FormInputComponent
                                        formInputLayout="column"
                                        fieldName="standName"
                                        fieldDescription="Nombre del Qr (sólo disponible para admins y super-admins)."
                                        fieldPlaceholder=""
                                    />
                                )}
                                {/* <hr /> */}
                                <Accordion flush>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <h3>Permisos</h3>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            {auth.loggedUserData.userType ===
                                                "superadmin" &&
                                                adminUsers && (
                                                    <FormInputComponent
                                                        formInputLayout="column"
                                                        fieldAs="select"
                                                        fieldSelect={adminUsers}
                                                        fieldName="owner"
                                                        fieldDescription="Usuario Owner"
                                                        fieldPlaceholder="Seleccione el Owner"
                                                    />
                                                )}

                                            {(auth.loggedUserData.userType ===
                                                "admin" ||
                                                auth.loggedUserData.userType ===
                                                    "expositor") &&
                                                adminUsers && (
                                                    <FormInputComponent
                                                        formInputLayout="column"
                                                        fieldAs="select"
                                                        fieldSelect={adminUsers}
                                                        fieldName="owner"
                                                        disabled={true}
                                                        fieldDescription="Usuario Owner"
                                                        fieldPlaceholder="Seleccione el Owner"
                                                    />
                                                )}

                                            {auth.loggedUserData.userType !==
                                                "expositor" &&
                                                exhibitorUsers && (
                                                    <FormInputComponent
                                                        formInputLayout="column"
                                                        fieldAs="select"
                                                        multiple={true}
                                                        fieldSelect={
                                                            exhibitorUsers
                                                        }
                                                        fieldName="canEdit"
                                                        fieldDescription="Usuarios que pueden editar"
                                                        fieldPlaceholder=""
                                                    />
                                                )}

                                            {auth.loggedUserData.userType ===
                                                "expositor" &&
                                                exhibitorUsers && (
                                                    <FormInputComponent
                                                        formInputLayout="column"
                                                        fieldAs="select"
                                                        multiple={true}
                                                        disabled={true}
                                                        fieldSelect={
                                                            exhibitorUsers
                                                        }
                                                        fieldName="canEdit"
                                                        fieldDescription="Usuario que pueden editar"
                                                        fieldPlaceholder=""
                                                    />
                                                )}
                                            {auth.loggedUserData.userType !==
                                                "expositor" && (
                                                <FormInputComponent
                                                    formInputLayout="column"
                                                    fieldType="select"
                                                    fieldAs="select"
                                                    fieldSelect={[
                                                        {
                                                            value: "standard",
                                                            text: "Standard (5)",
                                                        },
                                                        {
                                                            value: "standardPlus",
                                                            text: "Standard Plus (10)",
                                                        },
                                                        {
                                                            value: "premium",
                                                            text: "Premium (15)",
                                                        },

                                                        {
                                                            value: "unlimited",
                                                            text: "Ilimitado",
                                                        },
                                                    ]}
                                                    fieldName="standPlan"
                                                    fieldDescription="Plan Fullexy"
                                                    fieldPlaceholder=""
                                                    onChange={(e) => {
                                                        planHandler(
                                                            e.target.value
                                                        );
                                                        setFieldValue(
                                                            "standPlan",
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            )}

                                            {auth.loggedUserData.userType !==
                                                "expositor" &&
                                                exhibitorUsers && (
                                                    <FormInputComponent
                                                        formInputLayout="column"
                                                        fieldType="select"
                                                        fieldAs="select"
                                                        fieldSelect={[
                                                            {
                                                                value: "Activo",
                                                                text: "Si",
                                                            },
                                                            {
                                                                value: "Inactivo",
                                                                text: "No",
                                                            },
                                                            {
                                                                value: "Eliminado",
                                                                text: "Eliminado",
                                                            },
                                                        ]}
                                                        fieldName="active"
                                                        fieldDescription="¿Querés que tu QR ya esté visible?"
                                                        fieldPlaceholder=""
                                                    />
                                                )}

                                            {auth.loggedUserData.userType ===
                                                "expositor" &&
                                                exhibitorUsers && (
                                                    <FormInputComponent
                                                        formInputLayout="column"
                                                        fieldType="select"
                                                        fieldAs="select"
                                                        fieldSelect={[
                                                            {
                                                                value: "Activo",
                                                                text: "Si",
                                                            },
                                                            {
                                                                value: "Inactivo",
                                                                text: "No",
                                                            },
                                                        ]}
                                                        fieldName="active"
                                                        fieldDescription="¿Querés que tu QR ya esté visible?"
                                                        fieldPlaceholder=""
                                                    />
                                                )}

                                            <FormInputComponent
                                                formInputLayout="column"
                                                fieldType="select"
                                                fieldAs="select"
                                                fieldSelect={[
                                                    { value: true, text: "Si" },
                                                    {
                                                        value: false,
                                                        text: "No",
                                                    },
                                                ]}
                                                fieldName="requiresLogin"
                                                fieldDescription="¿Requiere estar logueado para ver?"
                                                fieldPlaceholder=""
                                            />
                                            <FormInputComponent
                                                formInputLayout="column"
                                                fieldType="select"
                                                fieldAs="select"
                                                fieldSelect={[
                                                    { value: true, text: "Si" },
                                                    {
                                                        value: false,
                                                        text: "No",
                                                    },
                                                ]}
                                                fieldName="uniqueLikeOnStand"
                                                fieldDescription="Me Gusta único en cards asociadas"
                                                fieldPlaceholder=""
                                            />
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    {/* <hr /> */}

                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                            <h3>Estilo</h3>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <FormInputComponent
                                                formInputLayout="column"
                                                fieldType="file"
                                                fieldName="mainBanner"
                                                fieldDescription="Banner del Qr: Este banner será la portada principal del Qr. Debe ser una imagen PNG/JPG/GIF de idealmente, 1082px por 2000px o múltiplos."
                                                fieldPlaceholder=""
                                                fileUpload={true}
                                                setFieldValue={setFieldValue}
                                            />

                                            <FormInputComponent
                                                formInputLayout="column"
                                                fieldType="file"
                                                fieldName="navIcon"
                                                fieldDescription="Logo de la Navbar: El logo estará ubicado en la parte superior izquierda de la pantalla, sobre la navbar. Debe ser una imagen PNG/JPG/GIF de idealmente, 140px por 28px o múltiplos."
                                                fieldPlaceholder=""
                                                fileUpload={true}
                                                setFieldValue={setFieldValue}
                                            />

                                            <FormInputComponent
                                                formInputLayout="column"
                                                fieldType="text"
                                                fieldName="hexColor"
                                                fieldDescription="Color principal: Aplica en primer titulo de las cards y en el fondo de todos los botones "
                                                fieldPlaceholder="#000000"
                                                colorPicker={true}
                                                colorPickerHandler={() =>
                                                    showHexPickerHandler(
                                                        values.hexColor,
                                                        "hexColor"
                                                    )
                                                }
                                            />

                                            <FormInputComponent
                                                formInputLayout="column"
                                                fieldType="text"
                                                fieldName="textColor"
                                                fieldDescription="Color del Texto: Aplica en el texto de todos los botones del QR "
                                                fieldPlaceholder="#000000"
                                                colorPicker={true}
                                                colorPickerHandler={() =>
                                                    showHexPickerHandler(
                                                        values.textColor,
                                                        "textColor"
                                                    )
                                                }
                                            />

                                            <FormInputComponent
                                                formInputLayout="column"
                                                fieldType="text"
                                                fieldName="navColor"
                                                fieldDescription="Color de la Navbar: Aplica al color de la barra de navegación superior "
                                                fieldPlaceholder="#000000"
                                                colorPicker={true}
                                                colorPickerHandler={() =>
                                                    showHexPickerHandler(
                                                        values.navColor,
                                                        "navColor"
                                                    )
                                                }
                                            />

                                            <FormInputComponent
                                                formInputLayout="column"
                                                fieldType="text"
                                                fieldName="navButtonsColor"
                                                fieldDescription="Color de botones de la Navbar: Aplica a los hipervinculos de la parte derecha de la navbar, que dirigen a perfil, cerrar sesión, etc."
                                                fieldPlaceholder="#000000"
                                                colorPicker={true}
                                                colorPickerHandler={() =>
                                                    showHexPickerHandler(
                                                        values.navButtonsColor,
                                                        "navButtonsColor"
                                                    )
                                                }
                                            />

                                            <FormInputComponent
                                                formInputLayout="column"
                                                fieldType="select"
                                                fieldAs="select"
                                                fieldSelect={[
                                                    {
                                                        value: "font-open-sans",
                                                        text: "Open Sans",
                                                        font: "font-open-sans",
                                                    },
                                                    {
                                                        value: "font-roboto",
                                                        text: "Roboto",
                                                        font: "font-roboto",
                                                    },
                                                    {
                                                        value: "font-mouse-memoirs",
                                                        text: "Mouse Memoirs",
                                                        font: "font-mouse-memoirs",
                                                    },
                                                    {
                                                        value: "font-monserrat",
                                                        text: "Monserrat",
                                                        font: "font-monserrat",
                                                    },
                                                    {
                                                        value: "font-lato",
                                                        text: "Lato",
                                                        font: "font-lato",
                                                    },
                                                ]}
                                                fieldName="selectedFont"
                                                fieldDescription="Seleccione la tipografía para su Qr"
                                                fieldPlaceholder=""
                                            />
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    {/* <hr /> */}
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                            <h3>Módulos/Cards</h3>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div className="accordion__explanation-text">
                                                Las cards funcionan como módulos
                                                de contenido dentro del Qr.
                                                Podes ir agregando los módulos
                                                que quieras.
                                            </div>
                                            <div className="generic-form__error-placeholder generic-form__error-placeholder--column">
                                                <ErrorMessage
                                                    name="cards"
                                                    component="div"
                                                    className="generic-form__validation-error"
                                                />
                                            </div>
                                            <FieldArray name="cards">
                                                {({ swap, remove, push }) => (
                                                    <div className="generic-form fieldarray__container">
                                                        {values.cards.length >
                                                            0 &&
                                                            values.cards.map(
                                                                (
                                                                    card,
                                                                    index
                                                                ) => (
                                                                    <div
                                                                        className="generic-form__fieldarray-card"
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <div className="generic-form__fieldarray-card-movebuttons">
                                                                            <button
                                                                                onClick={() =>
                                                                                    swapHandler(
                                                                                        swap,
                                                                                        index,
                                                                                        index -
                                                                                            1,
                                                                                        values
                                                                                            .cards
                                                                                            .length
                                                                                    )
                                                                                }
                                                                                className=""
                                                                                type="button"
                                                                            >
                                                                                <BsArrowUp />
                                                                            </button>
                                                                            <div className="generic-form__fieldarray-card-position">
                                                                                {index +
                                                                                    1}
                                                                            </div>
                                                                            <button
                                                                                onClick={() =>
                                                                                    swapHandler(
                                                                                        swap,
                                                                                        index,
                                                                                        index +
                                                                                            1,
                                                                                        values
                                                                                            .cards
                                                                                            .length
                                                                                    )
                                                                                }
                                                                                className=""
                                                                                type="button"
                                                                            >
                                                                                <BsArrowDown />
                                                                            </button>
                                                                        </div>
                                                                        <div className="mt-5">
                                                                            <button
                                                                                onClick={() =>
                                                                                    handleShowTemplates()
                                                                                }
                                                                                className="maintemplates-button"
                                                                                type="button"
                                                                            ><BsCardChecklist className="standlist-imageButton__iconblack" />
                                                                                Ejemplo
                                                                                de
                                                                                Módulos
                                                                            </button>
                                                                        </div>
                                                                        <FormInputComponent
                                                                            fieldType="select"
                                                                            fieldAs="select"
                                                                            fieldSelect={Object.values(
                                                                                cardTypes
                                                                            )}
                                                                            fieldName={`cards.${index}.cardType`}
                                                                            fieldDescription="Tipos de Módulos"
                                                                            fieldPlaceholder=""
                                                                        />

                                                                        {CardPicker(
                                                                            {
                                                                                index: index,
                                                                                showHexPickerHandler:
                                                                                    showHexPickerHandler,
                                                                                setFieldValue:
                                                                                    setFieldValue,
                                                                                card: card,
                                                                            }
                                                                        )}

                                                                        <Button
                                                                            type="button"
                                                                            className="main-button--warning mt-3"
                                                                            onClick={() =>
                                                                                remove(
                                                                                    index
                                                                                )
                                                                            }
                                                                        ><BsFillDashCircleFill className="standlist-imageButton__iconblack" />
                                                                            Remover
                                                                            Card
                                                                        </Button>
                                                                    </div>
                                                                )
                                                            )}
                                                        {values.cards.length >=
                                                            stateStandPlan.cardsLimit && (
                                                            <Button
                                                                type="button"
                                                                className="main-button--disabled"
                                                                disabled={true}
                                                            > 
                                                            <BsFillInfoCircleFill className="standlist-imageButton__iconblack" /> 
                                                                Límite de cards
                                                                alcanzado
                                                            </Button>
                                                        )}
                                                        {!(
                                                            values.cards
                                                                .length >=
                                                            stateStandPlan.cardsLimit
                                                        ) && (
                                                            <Button
                                                                type="button"
                                                                className=""
                                                                onClick={() =>
                                                                    push({
                                                                        cardType:
                                                                            "DefaultStandCard",
                                                                        firstTitle:
                                                                            "",
                                                                        secondTitle:
                                                                            "",
                                                                        description:
                                                                            "",
                                                                        cardTextColor:
                                                                            "",
                                                                        image: null,
                                                                        latitude:
                                                                            "",
                                                                        longitude:
                                                                            "",
                                                                        buttonType:
                                                                            "",
                                                                        buttonLink:
                                                                            "",
                                                                        buttonText:
                                                                            "",
                                                                        videoLink:
                                                                            "",
                                                                        interestedButton: false,
                                                                    })
                                                                }
                                                            >  <BsFillPlusCircleFill className="standlist-imageButton__iconblack" />
                                                                Agregar card
                                                            </Button>
                                                        )}
                                                    </div>
                                                )}
                                            </FieldArray>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    {/* <hr /> */}
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                            <h3>Encuesta Satisfacción</h3>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                             <div className="accordion__explanation-text">
                                                A continuación puede crear la
                                                encuesta de satisfacción.
                                                Recurde crear el módulo de
                                                Encuesta Satisfacción en la
                                                sección Módulos/Cards.
                                            </div>
                                            <FieldArray name="customFormFields">
                                                {({ remove, push }) => (
                                                    <div className="generic-form fieldarray__container">
                                                        {values.customFormFields
                                                            .length > 0 &&
                                                            values.customFormFields.map(
                                                                (
                                                                    customFormField,
                                                                    groupIndex
                                                                ) => (
                                                                    <div
                                                                        key={
                                                                            groupIndex
                                                                        }
                                                                        className="generic-form__content generic-form__fieldarray-card"
                                                                    >
                                                                        <div className="generic-form__input-block  mb-3">
                                                                            <FormInputComponent
                                                                                fieldType="text"
                                                                                fieldName={`customFormFields.${groupIndex}.fieldName`}
                                                                                fieldDescription="Nombre del campo"
                                                                                fieldPlaceholder=""
                                                                            />

                                                                            <FormInputComponent
                                                                                fieldType="select"
                                                                                fieldAs="select"
                                                                                fieldSelect={[
                                                                                    {
                                                                                        value: 0,
                                                                                        text: "Seleccione",
                                                                                    },
                                                                                    {
                                                                                        value: "text",
                                                                                        text: "Texto Libre",
                                                                                    },
                                                                                    {
                                                                                        value: "select",
                                                                                        text: "Select Desplegable",
                                                                                    },
                                                                                ]}
                                                                                fieldName={`customFormFields.${groupIndex}.fieldType`}
                                                                                fieldDescription="Tipo de Campo"
                                                                                fieldPlaceholder=""
                                                                            />

                                                                            {values
                                                                                .customFormFields[
                                                                                groupIndex
                                                                            ]
                                                                                .fieldType ===
                                                                                "text" && (
                                                                                <FormInputComponent
                                                                                    fieldType="text"
                                                                                    fieldName={`customFormFields.${groupIndex}.fieldPlaceholder`}
                                                                                    fieldDescription={`Valor "por defecto" del campo`}
                                                                                    fieldPlaceholder=""
                                                                                />
                                                                            )}

                                                                            {values
                                                                                .customFormFields[
                                                                                groupIndex
                                                                            ]
                                                                                .fieldType ===
                                                                                "select" && (
                                                                                <FormInputComponent
                                                                                    fieldType="text"
                                                                                    fieldName={`customFormFields.${groupIndex}.fieldOptions`}
                                                                                    fieldDescription={`Ingrese los valores separados por punto y coma`}
                                                                                    fieldPlaceholder={`Ejemplo: Textil;Comercial;Ganadero`}
                                                                                />
                                                                            )}
                                                                        </div>
                                                                        <Button
                                                                            type="button"
                                                                            className="main-button--warning"
                                                                            onClick={() =>
                                                                                remove(
                                                                                    groupIndex
                                                                                )
                                                                            }
                                                                        ><BsFillDashCircleFill className="standlist-imageButton__iconblack" />
                                                                            Remover
                                                                            Campo
                                                                        </Button>
                                                                    </div>
                                                                )
                                                            )}
                                                        <Button
                                                            type="button"
                                                            className=""
                                                            onClick={() =>
                                                                push({
                                                                    fieldName:
                                                                        "",
                                                                    fieldDescription:
                                                                        "",
                                                                    fieldType:
                                                                        "",
                                                                    fieldAs: "",
                                                                    fieldPlaceholder:
                                                                        "",
                                                                    fieldOptions:
                                                                        "",
                                                                })
                                                            }
                                                        > <BsFillPlusCircleFill className="standlist-imageButton__iconblack" />
                                                            Agregar Campo
                                                            Adicional
                                                        </Button>
                                                    </div>
                                                )}
                                            </FieldArray>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    {/* <hr /> */}
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                            <h3>Redes Sociales</h3>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                             <div className="accordion__explanation-text">
                                                Ingresar las Redes Sociales. Los
                                                iconos se mostrarán hacia el
                                                final del Qr junto con los
                                                botones de contacto.{" "}
                                            </div>
                                            <FieldArray name="networks">
                                                {({ remove, push }) => (
                                                    <div className="generic-form fieldarray__container">
                                                        {values.networks
                                                            .length > 0 &&
                                                            values.networks.map(
                                                                (
                                                                    network,
                                                                    index
                                                                ) => (
                                                                    <div
                                                                        key={
                                                                            index
                                                                        }
                                                                        className="generic-form__content generic-form__fieldarray-card"
                                                                    >
                                                                        <div className="generic-form__input-block generic-form__input-block--grid mb-3">
                                                                            <FormInputComponent
                                                                                fieldType="select"
                                                                                fieldAs="select"
                                                                                fieldSelect={[
                                                                                    {
                                                                                        value: "",
                                                                                        text: "",
                                                                                    },
                                                                                    {
                                                                                        value: "Instagram",
                                                                                        text: "Instagram",
                                                                                    },
                                                                                    {
                                                                                        value: "Facebook",
                                                                                        text: "Facebook",
                                                                                    },
                                                                                    {
                                                                                        value: "LinkedIn",
                                                                                        text: "LinkedIn",
                                                                                    },
                                                                                    {
                                                                                        value: "Twitter",
                                                                                        text: "Twitter",
                                                                                    },
                                                                                    {
                                                                                        value: "General",
                                                                                        text: "General",
                                                                                    },
                                                                                ]}
                                                                                fieldName={`networks.${index}.type`}
                                                                                fieldDescription="Tipo"
                                                                                fieldPlaceholder=""
                                                                            />

                                                                            <FormInputComponent
                                                                                fieldType="text"
                                                                                fieldName={`networks.${index}.link`}
                                                                                fieldDescription="Link (Sin incluir https://)"
                                                                                fieldPlaceholder=""
                                                                            />
                                                                        </div>
                                                                        <Button
                                                                            type="button"
                                                                            className="main-button--warning"
                                                                            onClick={() =>
                                                                                remove(
                                                                                    index
                                                                                )
                                                                            }
                                                                        > <BsFillDashCircleFill className="standlist-imageButton__iconblack" />
                                                                            Remover
                                                                            Red
                                                                        </Button>
                                                                    </div>
                                                                )
                                                            )}
                                                        <Button
                                                            type="button"
                                                            className=""
                                                            onClick={() =>
                                                                push({
                                                                    type: "",
                                                                    link: "",
                                                                })
                                                            }
                                                        >  <BsFillPlusCircleFill className="standlist-imageButton__iconblack" />
                                                            Agregar Red de
                                                            Contacto
                                                        </Button>
                                                    </div>
                                                )}
                                            </FieldArray>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    {/* <hr /> */}
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                            <h3>Contacto</h3>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <FormInputComponent
                                                formInputLayout="column"
                                                fieldType="text"
                                                fieldName="emailButtonText"
                                                fieldDescription="Texto de botón de Email"
                                                fieldPlaceholder=""
                                            />

                                            <FormInputComponent
                                                formInputLayout="column"
                                                fieldType="text"
                                                fieldName="emailButtonLink"
                                                fieldDescription="Link de botón de Email: A continuación deberá colocar el mail"
                                                fieldPlaceholder=""
                                            />

                                            <FormInputComponent
                                                formInputLayout="column"
                                                fieldType="text"
                                                fieldName="whatsappButtonText"
                                                fieldDescription="Texto de botón de WhatsApp"
                                                fieldPlaceholder=""
                                            />

                                            <FormInputComponent
                                                formInputLayout="column"
                                                fieldType="text"
                                                fieldName="whatsappButtonLink"
                                                fieldDescription="Link de botón de WhatsApp: A continuación deberá colocar número de teléfono sin el signo +"
                                                fieldPlaceholder=""
                                            />
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    {/* <hr /> */}

                                    <React.Fragment>
                                        <Accordion.Item eventKey="6">
                                            <Accordion.Header>
                                                <h3>Datos Visitantes</h3>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                 <div className="accordion__explanation-text">
                                                    A continuación deberá
                                                    indicar que datos se le
                                                    pedirán a los usuarios que
                                                    visiten su Qr.{" "}
                                                </div>
                                                <FieldArray name="formFields">
                                                    <div id="formFieldsValueUpdate">
                                                        {variableFormFields.map(
                                                            (
                                                                formField,
                                                                index
                                                            ) => {
                                                                if (
                                                                    setInitialValuesFormFields(
                                                                        index
                                                                    ) === 0
                                                                ) {
                                                                    return (
                                                                        <FormInputComponent
                                                                            key={
                                                                                index
                                                                            }
                                                                            formInputLayout="column"
                                                                            fieldName={`formFields.${index}`}
                                                                            fieldType="select"
                                                                            selectedValue={setInitialValuesFormFields(
                                                                                index
                                                                            )}
                                                                            fieldAs="select"
                                                                            fieldSelect={[
                                                                                {
                                                                                    value: 0,
                                                                                    text: "No",
                                                                                },
                                                                                {
                                                                                    value: formField._id,
                                                                                    text: "Si",
                                                                                },
                                                                            ]}
                                                                            fieldDescription={
                                                                                formField.fieldDescription
                                                                            }
                                                                        />
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <FormInputComponent
                                                                            key={
                                                                                index
                                                                            }
                                                                            formInputLayout="column"
                                                                            fieldName={`formFields.${index}`}
                                                                            fieldType="select"
                                                                            selectedValue={setInitialValuesFormFields(
                                                                                index
                                                                            )}
                                                                            fieldAs="select"
                                                                            fieldSelect={[
                                                                                {
                                                                                    value: 0,
                                                                                    text: "No",
                                                                                },
                                                                                {
                                                                                    selected: true,
                                                                                    value: formField._id,
                                                                                    text: "Si",
                                                                                },
                                                                            ]}
                                                                            fieldDescription={
                                                                                formField.fieldDescription
                                                                            }
                                                                        />
                                                                    );
                                                                }
                                                            }
                                                        )}
                                                    </div>
                                                </FieldArray>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </React.Fragment>
                                </Accordion>
                                <div className="generic-form__footer">
                                        <Button
                                            className=""
                                            type="submit"
                                            status="active"
                                        >
                                        <BsFillSaveFill className="standlist-imageButton__iconblack" />
                                            Actualizar
                                        </Button>
                                </div>
                            </GenericForm>
                        </React.Fragment>
                    )}
                </Formik>
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>
                <div>Cargando</div>
            </React.Fragment>
        );
    }
};

export default UpdateStand;
