import React, { useEffect } from "react";

import GenericModal from "../../../shared/components/UIElements/GenericModal";
import MainNavigation from "../../../shared/components/Navigation/MainNavigation";

import "./PreviewModal.css";

import { Stand } from "../../../stand/pages/StandPage";

const PreviewModal = (props) => {
    const modifiedProps = props.stand;

    useEffect(() => {
        if (modifiedProps.mainBanner instanceof File) {
            modifiedProps.mainBannerPreview = URL.createObjectURL(
                modifiedProps.mainBanner
            );
        }

        if (modifiedProps.navIcon instanceof File) {
            modifiedProps.navIconPreview = URL.createObjectURL(
                modifiedProps.navIcon
            );
        } else if (modifiedProps.navIcon !== undefined) {
            modifiedProps.navIconPreview = `${process.env.REACT_APP_API_URL}/${modifiedProps.navIcon}`;
        } else {
            modifiedProps.navIconPreview = undefined;
        }

        if (modifiedProps.cards.length) {
            modifiedProps.cards.forEach((card) => {
                if (card.image instanceof File) {
                    card.imagePreview = URL.createObjectURL(card.image);
                }
            });
        }
    }, [props, modifiedProps]);

    return (
        <GenericModal
            show={props.show}
            error={false}
            title={"QR Preview"}
            buttonless={false}
            closeFunction={props.onClose}
            className={"stand-preview__modal"}
        >
            <div className="stand-preview__container">
                <MainNavigation preview={true} {...modifiedProps} />
                <Stand preview={true} {...modifiedProps} />
            </div>
        </GenericModal>
    );
};

export default PreviewModal;
