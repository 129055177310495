import React, { useContext, useState, useEffect } from "react";
import {
    FormInputComponent,
    GenericForm,
} from "../../shared/components/FormElements/GenericForm";
import { Formik, Form } from "formik";

import Button from "../../shared/components/FormElements/Button";
import GenericModal from "../../shared/components/UIElements/GenericModal";

import "./SingleQuestionCard.css";
import { BsFillArrowRightCircleFill } from "react-icons/bs";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/AuthContext";

const SingleQuestionCard = (props) => {
    const auth = useContext(AuthContext);
    const { sendRequest, clearError } = useHttpClient();
    const [modalShow, setModalShow] = useState(false);
    const [filteredSelect, setFilteredSelect] = useState(false);

    useEffect(() => {
        let selectArray = [];
        if (props.formInformation.fieldSelect) {
            selectArray = props.formInformation.fieldSelect
                .split(";")
                .map((item) => ({ value: item, text: item }));
        }
        setFilteredSelect(selectArray);
    }, [props.fieldSelect, props.formInformation.fieldSelect]);

    return (
        <React.Fragment>
            <Formik
                initialValues={{
                    singleQuestionAnswer: null,
                }}
                onSubmit={async (values, {resetForm}) => {
                    clearError();
                    try {
                        const responseData = await sendRequest(
                            `${process.env.REACT_APP_API_URL}/api/qrs/${props.standName}/${props._id}/cardformsubmit`,
                            "POST",
                            JSON.stringify({
                                singleQuestionAnswer:
                                    values.singleQuestionAnswer,
                            }),
                            {
                                "Content-Type": "application/json",
                                Authorization: "Bearer " + auth.token,
                            }
                        );

                        if (responseData.interested === "sent") {
                            resetForm({values:''});
                            setModalShow(false);
                        }
                    } catch (err) {}
                }}
            >
                {() => (
                    <GenericModal
                        title={"Responder Pregunta"}
                        nonError={true}
                        buttonless={true}
                        show={modalShow}
                        setModalShow={setModalShow}
                    >
                        <Form className="lottery-update-form">
                            <div>
                                {props.formInformation.fieldAs === "text" && (
                                    <FormInputComponent
                                        fieldName={"singleQuestionAnswer"}
                                        fieldDescription={
                                            props.formInformation
                                                .fieldDescription
                                        }
                                        fieldPlaceholder={
                                            props.formInformation
                                                .fieldPlaceholder
                                        }
                                        className={"pb-4"}
                                    />
                                )}

                                {props.formInformation.fieldAs === "select" && (
                                    <FormInputComponent
                                        fieldAs={"select"}
                                        fieldType={"select"}
                                        fieldName={"singleQuestionAnswer"}
                                        fieldDescription={
                                            props.formInformation
                                                .fieldDescription
                                        }
                                        fieldSelect={filteredSelect}
                                        className={"pb-4"}
                                    />
                                )}

                                <Button
                                    className=""
                                    type="submit"
                                    status="active"
                                ><BsFillArrowRightCircleFill className="backend-imageButton__icon" />
                                    Enviar
                                </Button>
                            </div>
                        </Form>
                    </GenericModal>
                )}
            </Formik>
            <div
                className={`default-stand-card ${
                    props.index === 0 ? "default-stand-card--firstchild" : ""
                } ${props.preview ? "default-stand-card--preview" : ""}`}
            >
                <div className="question-stand-card__title">
                    <span
                        className="default-stand-card__title"
                        style={{ color: props.cardTextColor }}
                    >
                        {props.firstTitle}
                    </span>
                </div>
                <div className="d-flex justify-content-center">
                    <Button
                        className="d-flex justify-content-center main-button--squared-bigger main-button--40-width"
                        onClick={() => setModalShow(true)}
                        status="active"
                    >
                        {props.buttonText}
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default SingleQuestionCard;
