import React, { useState,useEffect }  from "react";
import { useParams } from "react-router-dom";

import "./LotteryPage.css";
import { useHttpClient } from "../../shared/hooks/http-hook";

const LotteryPage = () => {

    const lotteryName = useParams().lotteryName;
    const { sendRequest } = useHttpClient();
    const [loadedLottery, setLoadedLottery] = useState();
    

    useEffect(() => {
        const fetchLotterys = async () => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_API_URL}/api/lottery/details/${lotteryName}`,
                    "GET",
                    null
                );
                setLoadedLottery(responseData.lotterydetail);
            } catch (err) {}
        };
        fetchLotterys();
    }, [lotteryName,sendRequest]);
   
    return (
    
        <React.Fragment>
        {loadedLottery && loadedLottery.map((item) => (
            
            <div className="lottery-page__container">

                <div className="lottery-page__container-top">
                    <p className="lottery-page__main-title"><b>{item.titleName}</b></p>
                    <div className="lottery-page__container-info">
                    <p><b>Id</b><br></br><span>{item.id}</span></p>               
                    <p><b>Fecha del sorteo:</b><br></br><span>{item.fecha}</span></p>
                    <p><b>Participantes del sorteo:</b><br></br><span>{item.participants}</span></p>
                    </div>
               
                </div>
                
                <div className="lottery-page__container-bottom">
                 <p><b>Ganadores del sorteo:</b></p>
                 {item.winners.map((ganadores) => (
                 <p className="lottery-page__winners">{ganadores}</p>
                 ))}
                 <p><b>Suplentes del sorteo:</b></p>
                 {item.backups.map((backups) => (
                 <p className="lottery-page__winners">{backups}</p>
                 ))}
                </div>
                
               
               
            </div>
            
             ))}
        </React.Fragment>
    );
};

export default LotteryPage;
