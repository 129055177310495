import React, { useState, useContext, useEffect } from "react";

// eslint-disable-next-line no-unused-vars
import Chart from "chart.js/auto";

import UserPagination from "../../../shared/components/UIElements/Pagination";
import { useParams } from "react-router-dom";
import BannerStands from '../../stands/assets/Banner5.png';
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/AuthContext";
import Button from "../../../shared/components/FormElements/Button";
import { BsFillArrowLeftCircleFill, BsFillSaveFill } from "react-icons/bs";
import "./Analytics.css";
import { Bar } from "react-chartjs-2";

const Analytics = () => {
    const auth = useContext(AuthContext);
    const standName = useParams().standName;
    const { isLoading, sendRequest } = useHttpClient();
    const [loadedUsers, setLoadedUsers] = useState();
    const [uniqueLike, setUniqueLike] = useState(false);

    const [uniqueLikeArray, setUniqueLikeArray] = useState([]);

    const [totalCount, setTotalCount] = useState(0);
    const [totalInterestCount, setTotalInterestCount] = useState(0);
    const [uniqueVisitors, setUniqueVisitors] = useState(0);
    const [uniqueVisitorsDate, setUniqueVisitorsDate] = useState(0);
    const [loggedUser, setLoggedUser] = useState(null);
    const [userrefresh, setUsersRefresh] = useState(false);
    const [graphData, setGraphData] = useState(null);

    const handleShowSignup = () => {
        const link = document.createElement("a");
        link.href = `${process.env.REACT_APP_API_URL}/uploads/images/${standName}.xlsx`;
        //link.setAttribute('download',`${stand._id}.xlsx`,);
        link.download = `${standName}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const today = new Date();
    const todaydate = `${today.getDate()}/${today.getMonth()+1}/${today.getFullYear()}`;

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_API_URL}/api/qrs/users/${standName}`,
                    "GET",
                    null,
                    { Authorization: "Bearer " + auth.token }
                );
                setLoadedUsers(responseData.users);
                setUniqueLike(responseData.stand.uniqueLikeOnStand);
                responseData.stand.uniqueLikeOnStand &&
                    setUniqueLikeArray(responseData.uniqueLike);

                if (responseData.users) {
                    setTotalCount(responseData.users.length);
                    setUniqueVisitors(
                        [
                            ...new Set(
                                responseData.users.map((user) => user.mail)
                            ),
                        ].length
                    );
                    graphParser(responseData.users);
                }
                if (responseData.cards) {
                    setTotalInterestCount(responseData.cards.length);
                }
                let countdates=0;
                responseData.users.map((user) => 
                {
                    if (todaydate === user.date){
                        countdates++;
                    }
                })
                setUniqueVisitorsDate(countdates);
                setUsersRefresh(false);
            } catch (err) {}
        };
        fetchUsers();
    }, [sendRequest, standName, userrefresh, auth.token]);

    useEffect(() => {
        const fetchUser = async () => {
            if (auth.userId) {
                try {
                    const responseData = await sendRequest(
                        `${process.env.REACT_APP_API_URL}/api/users/${auth.userId}`,
                        "GET",
                        null,
                        { Authorization: "Bearer " + auth.token }
                    );
                    setLoggedUser(responseData.user);
                } catch (err) {}
            } else {
                setLoggedUser(null);
            }
        };
        fetchUser();
    }, [auth.login, auth.logout, auth.userId, auth.token, sendRequest]);

    const graphParser = (loadedUsers) => {
        const dates = [];
        const counts = {};

        loadedUsers.forEach((user) => {
            dates.push(user.date);
        });

        for (const num of dates) {
            counts[num] = counts[num] ? counts[num] + 1 : 1;
        }

        setGraphData({
            uniqueDates: Object.keys(counts),
            visitorCountPerDay: Object.values(counts),
        });
    };

    return (
        <React.Fragment>
            <img src={BannerStands} width="100%" alt="" />
            <div className="generic-form__button-block"> 
                                <Button
                                    to="/backend/qrs"
                                    className="stands-list__back-button"
                                >
                                    <BsFillArrowLeftCircleFill className="standlist-imageButton__iconblack" />
                                    Atrás
                                </Button>
            </div>
            <div className="analytics__header">
                <div className="analytics__header__button">
                    <Button status="active" onClick={handleShowSignup}>
                    <BsFillSaveFill className="standlist-imageButton__iconblack" />
                        Descargar Analíticas
                    </Button>
                </div>
                
            </div>
            <div className="analytics-card--grid">
                
                        <div className="analytics-card">
                            <span className="analytics__header--titles">
                                Visitas Totales
                            </span>
                           <p className="analytics__header--number">{totalCount}</p>
                        </div>
                        <div className="analytics-card">
                            <span className="analytics__header--titles">
                                Visitas únicas
                            </span>
                            <p className="analytics__header--number">{uniqueVisitors}</p>
                        </div>
                        <div className="analytics-card">
                            <span className="analytics__header--titles">
                                Click en me interesa
                            </span>
                            <p className="analytics__header--number">{totalInterestCount}</p> 
                        </div>
                        <div className="analytics-card">
                            <span className="analytics__header--titles">
                                Visitas Hoy
                            </span>
                            <p className="analytics__header--number">{uniqueVisitorsDate}</p> 
                        </div>
            </div>
            <div className="analytics-graphs-main-container">
            {graphData && (
                        <div className="analytics-graph-container">
                            <Bar
                                datasetIdKey="id"
                                data={{
                                    labels: graphData.uniqueDates,
                                    datasets: [
                                        {
                                            label: "N° de visitas diarias",
                                            data: graphData.visitorCountPerDay,
                                            backgroundColor:
                                                "#26bb92",
                                            borderColor:
                                                "#26bb92",
                                            borderWidth: 1,
                                        },
                                    ],
                                }}
                            />
                        </div>
                    )}
                {/* {uniqueLike && uniqueLikeArray && (
                        <div className="analytics-list__container">
                            <table className="analytics-users-list__container-table">
                                <thead>
                                    <tr>
                                        <th>Título de Card</th>
                                        <th>Cantidad de Interesados</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {uniqueLikeArray.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.cardtitle}</td>
                                            <td>{item.count}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                )} */}

            </div>
            {!isLoading && loggedUser && loadedUsers && (
                <UserPagination
                    itemsPerPage={10}
                    userslist={loadedUsers}
                    user={loggedUser}
                    refresh={setUsersRefresh}
                    items={loadedUsers}
                />
            )}
           
        </React.Fragment>
    );
};

export default Analytics;
