import React, { useContext, useCallback, useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

import "./StandPage.css";

import * as Yup from "yup";

import { StandNavContext } from "../../shared/context/StandNavContext";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/AuthContext";

import GenericModal from "../../shared/components/UIElements/GenericModal";
import CustomQuestionsForm from "../../shared/components/FormElements/CustomQuestionsForm";

import AuthModal from "../../user/components/AuthModal";

import DefaultStandCard from "../components/DefaultStandCard";
import VideoStandCard from "../components/VideoStandCard";
import ImageStandCard from "../components/ImageStandCard";
import TitleTextStandCard from "../components/TitleTextStandCard";
import TitleStandCard from "../components/TitleStandCard";
import NumberTitleTextStandCard from "../components/NumberTitleTextStandCard";
import MapStandCard from "../components/MapStandCard";
import ReducedStandCard from "../components/ReducedStandCard";
import EcommerceStandCard from "../components/EcommerceStandCard";
import AccordionStandCard from "../components/AccordionStandCard";
import CarouselStandCard from "../components/CarouselStandCard";
import GenericFormCard from "../components/GenericFormCard";
import SingleQuestionCard from "../components/SingleQuestionCard";
import CustomQuestionsCard from "../components/CustomQuestionsCard";

import Button from "../../shared/components/FormElements/Button";

import {
    BsWhatsapp,
    BsInstagram,
    BsFacebook,
    BsLinkedin,
    BsTwitter,
} from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import { BiWorld } from "react-icons/bi";

export const Stand = (props) => {
    const cardTypes = {
        DefaultStandCard: DefaultStandCard,
        VideoStandCard: VideoStandCard,
        ImageStandCard: ImageStandCard,
        ReducedStandCard: ReducedStandCard,
        TitleTextStandCard: TitleTextStandCard,
        TitleStandCard: TitleStandCard,
        NumberTitleTextStandCard: NumberTitleTextStandCard,
        MapStandCard: MapStandCard,
        EcommerceStandCard: EcommerceStandCard,
        AccordionStandCard: AccordionStandCard,
        CarouselStandCard: CarouselStandCard,
        GenericFormCard: GenericFormCard,
        CustomQuestionsCard: CustomQuestionsCard,
        SingleQuestionCard: SingleQuestionCard,
    };
    //console.log(props);
    // console.log(<CustomQuestionsForm/>);
    const networkTypes = {
        Instagram: BsInstagram,
        Facebook: BsFacebook,
        LinkedIn: BsLinkedin,
        Twitter: BsTwitter,
        General: BiWorld,
    };

    return (
        <React.Fragment>
            {props.mainBanner !== 'uploads/default/default-banner.jpg' && (
                <img
                    src={
                        props.mainBannerPreview ||
                        `${process.env.REACT_APP_API_URL}/${props.mainBanner}`
                    }
                    alt=""
                    className="stand-banner"
                />
            )}
            <div className={props.selectedFont}>
                <div className="main-stand-container">
                    <div className={props.preview ? "" : "container"}>
                        {props.cards &&
                            props.cards.map((card, index) => {
                                let CardType = cardTypes[card.cardType];

                                return (
                                    <CardType
                                        key={index}
                                        index={index}
                                        handleInterested={() =>
                                            props.handleInterested(card._id)
                                        }
                                        hexColor={props.hexColor}
                                        buttonTextColor={props.textColor}
                                        preview={props.preview}
                                        standName={props.standName}
                                        auth={props.auth}
                                        customForm={props.customForm}
                                        initialValues={props.initialValues}
                                        helpSchema={props.helpSchema}
                                        clearError={props.clearError}
                                        sendRequest={props.sendRequest}
                                        standFetch={props.standFetch}
                                        error={props.error}
                                        formFields={props.formFields}
                                        customFormFields={
                                            props.customFormFields
                                        }
                                        history={props.history}
                                        {...card}
                                    />
                                );
                            })}
                    </div>
                    <div className="stand__contact-buttons">
                        {props.emailButtonLink && (
                            <Button
                                className="text-uppercase stand__contact-button"
                                type="submit"
                                href={`mailto:${props.emailButtonLink}?subject="Simple%20Stand"&body="Hola!%20Te%20escribo%20desde%20la%20plataforma%20de%20Simple%20Stand..."`}
                                style={{
                                    backgroundColor: props.hexColor,
                                    color: props.textColor,
                                }}
                                target="_blank"
                            >
                                <div className="stand__contact-button-text">
                                    <HiOutlineMail className="stand__contact-button-icon" />
                                    {props.emailButtonText}
                                </div>
                            </Button>
                        )}
                        {props.whatsappButtonLink && (
                            <Button
                                className="text-uppercase stand__contact-button"
                                type="submit"
                                href={`//wa.me/${props.whatsappButtonLink}?text=Hola!%20Te%20escribo%20desde%20la%20plataforma%20de%20Simple%20Stand...&type=phone_number&app_absent=0`}
                                style={{
                                    backgroundColor: props.hexColor,
                                    color: props.textColor,
                                }}
                                target="_blank"
                            >
                                <div className="stand__contact-button-text">
                                    <BsWhatsapp className="stand__contact-button-icon" />
                                    {props.whatsappButtonText}
                                </div>
                            </Button>
                        )}
                    </div>
                    <div className="stand__networks">
                        {props.networks.map((network, index) => {
                            if (network.type && network.link) {
                                let NetworkType = networkTypes[network.type];

                                return (
                                    <a
                                        key={index}
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`//${network.link}`}
                                        className="stand__network-button"
                                    >
                                        <NetworkType />
                                    </a>
                                );
                            } else {
                                return null;
                            }
                        })}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

const StandPage = () => {
    const auth = useContext(AuthContext);
    const standName = useParams().standName;

    const [showAuth, setShowAuth] = useState(false);
    const [showFormModal, setShowFormModal] = useState(false);
    const [modalType, setModalType] = useState(null);

    const [initialValues, setInitialValues] = useState({});
    const [helpSchema, setHelpSchema] = useState(Yup.object().shape({}));

    const [stand, setStand] = useState(null);
    const [formFields, setFormFields] = useState(null);
    const [customFormFields, setCustomFormFields] = useState(null);

    const history = useHistory();

    const handleCloseAuth = () => setShowAuth(false);

    const handleModalType = () => {
    };

    useEffect(() => {
        let formFieldsLoop = {};
        let helpSchemaLoop = {
            formFields: Yup.object(),
            customFormFields: Yup.object(),
        };
        if (formFields) {
            formFields.forEach((formField) => {
                formFieldsLoop[formField._id] = "";
                helpSchemaLoop.formFields[formField._id] =
                    Yup.mixed().required("Campo requerido");
            });
        }
        if (customFormFields) {
            customFormFields.forEach((formField) => {
                formFieldsLoop[formField._id] = "";
                helpSchemaLoop.customFormFields[formField._id] =
                    Yup.mixed().required("Campo requerido");
            });
        }

        setInitialValues(formFieldsLoop);
        setHelpSchema(Yup.object().shape(helpSchemaLoop));
    }, [formFields, customFormFields]);

    const { sendRequest, error, clearError } = useHttpClient();

    const { setStandNavContext } = useContext(StandNavContext);

    const likeChecker = (standCards) => {
        const alreadyLiked = [];
        standCards.forEach((card) => {
            if (card.interested) {
                alreadyLiked.push(card);
            }
        });

        return alreadyLiked;
    };

    const handleInterested = async (cardId) => {
        let likedCards = likeChecker(stand.cards);

        if (stand.uniqueLikeOnStand) {
            if (
                likedCards.length === 0 ||
                (likedCards.length === 1 && likedCards[0]._id === cardId)
            ) {
                try {
                    const responseData = await sendRequest(
                        `${process.env.REACT_APP_API_URL}/api/qrs/${standName}/${cardId}/interested`,
                        "POST",
                        undefined,
                        {
                            Authorization: "Bearer " + auth.token,
                            "Content-Type": "application/json",
                        }
                    );

                    let index = stand.cards.findIndex(
                        (card) => card._id === responseData.card
                    );
                    stand.cards[index].interested = responseData.interested;
                    setStand({ ...stand });
                } catch (err) {
                    console.log(err);
                }
            } else {
                alert(
                    "Solo puedes dar Me Interesa en una sola tarjeta para este evento."
                );
            }
        } else {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_API_URL}/api/qrs/${standName}/${cardId}/interested`,
                    "POST",
                    undefined,
                    {
                        Authorization: "Bearer " + auth.token,
                        "Content-Type": "application/json",
                    }
                );

                let index = stand.cards.findIndex(
                    (card) => card._id === responseData.card
                );
                stand.cards[index].interested = responseData.interested;
                setStand({ ...stand });
            } catch (err) {
                console.log(err);
            }
        }
        return;
    };

    const standFetch = useCallback(async () => {
        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_API_URL}/api/qrs/${standName}`,
                "GET",
                undefined,
                { Authorization: "Bearer " + auth.token }
            );
            // console.log(responseData);
            if (
                (!!responseData.additionalFormFields ||
                    !!responseData.customFormFields)
            ) {
                clearError();
                setFormFields(responseData.additionalFormFields);
                setCustomFormFields(responseData.customFormFields);
                setShowFormModal(true);
            } else {
                setFormFields(responseData.additionalFormFields);
                setCustomFormFields(responseData.customFormFields);
                setStand(responseData.stand);
                setStandNavContext({
                    navColor: responseData.stand.navColor,
                    navButtonsColor: responseData.stand.navButtonsColor,
                    navIcon: responseData.stand.navIcon,
                });
            }
        } catch (err) {
            setShowAuth(true);
            setModalType("signup");
        }
    }, [sendRequest, clearError, standName, auth.token, setStandNavContext]);

    //// editar para que se renueve la pagina al completar los campos

    useEffect(() => {
        if (!auth.authLoading) {
            standFetch();
        }
    }, [standFetch, auth.authLoading]);

    if (stand) {
        return (
            <Stand
                {...stand}
                handleInterested={handleInterested}
                initialValues={initialValues}
                helpSchema={helpSchema}
                clearError={clearError}
                sendRequest={sendRequest}
                auth={auth}
                standFetch={standFetch}
                standName={standName}
                error={error}
                formFields={formFields}
                customFormFields={customFormFields}
                history={history}
            />
        );
    } else if (!stand) {
        return (
            <React.Fragment>
                <AuthModal
                    show={showAuth}
                    onHide={handleCloseAuth}
                    setModalType={setModalType}
                    modalType={modalType}
                />

                <GenericModal
                    show={showFormModal}
                    error={false}
                    title={"Completar información adicional"}
                    buttonless={true}
                    onClear={clearError}
                    closeFunction={handleModalType}
                >
                    <CustomQuestionsForm
                        initialValues={initialValues}
                        helpSchema={helpSchema}
                        clearError={clearError}
                        sendRequest={sendRequest}
                        auth={auth}
                        standFetch={standFetch}
                        standName={standName}
                        error={error}
                        formFields={formFields}
                        customFormFields={customFormFields}
                        history={history}
                    />
                </GenericModal>
            </React.Fragment>
        );
    } else {
        return null;
    }
};

export default StandPage;
