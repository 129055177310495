import React, { useContext} from "react";
import { Formik} from "formik";
import { useHistory } from "react-router-dom";
import BackButton from "../../../shared/components/UIElements/BackButton";
import BannerNewStand from '../assets/Banner4.png'; 
import { BsFillPlusCircleFill } from "react-icons/bs";
import * as Yup from "yup";


import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/AuthContext";


import Button from "../../../shared/components/FormElements/Button";

import {
    GenericForm,
    FormInputComponent,
} from "../../../shared/components/FormElements/GenericForm";

import "./NewStand.css";

let STANDNAME_REGEXP = /^[a-z0-9]+$/;

const HelpSchema = Yup.object().shape({
    standName: Yup.string()
        .required("Campo requerido")
        .min(3, "Longitud mínima 3 caracteres")
        .max(20, "Longitud máxima 20 caracteres")
        .matches(
            STANDNAME_REGEXP,
            "Por favor sólo utilizar minúsculas y números"
        ),
});

const NewStand = () => {
    const auth = useContext(AuthContext);
    const { sendRequest, error, clearError } = useHttpClient();
    const history = useHistory();
    let initialValues = {
        standName: "",
        standPlan: "standard",
        requiresLogin: true,
        mainBanner: undefined,
        hexColor: "#26bb92",
        navColor: "#383838",
        navButtonsColor: "#f9f9f9",
        navIcon: undefined,
        textColor: "#333333",
        emailButtonLink: "",
        emailButtonText: "",
        whatsappButtonLink: "",
        whatsappButtonText: "",
        selectedFont: "font-open-sans",
        active: "Activo",
        uniqueLikeOnStand: false,
        owner: undefined,
        canEdit: [],
        networks: [],
        cards: [],
        formFields: [],
        customFormFields: [],
    };

        return (
            <React.Fragment>
                <Formik
                    initialValues={initialValues}
                    validateOnMount
                    validationSchema={HelpSchema}
                    onSubmit={async (values) => {
                        clearError();

                        try {
                            const formData = new FormData();

                            formData.append("standName", values.standName);
                            await sendRequest(
                                `${process.env.REACT_APP_API_URL}/api/qrs/`,
                                "POST",
                                formData,
                                { Authorization: "Bearer " + auth.token }
                            );
                            history.push(`/qrs/${values.standName}`);
                        } catch (err) {}
                    }}
                >
                    {({ values }) => (
                        <React.Fragment>
                            <img src={BannerNewStand} width="100%" alt="" />
                            <BackButton/>
                            <GenericForm title="Nuevo QR" error={error}>
                                <FormInputComponent
                                    formInputLayout="column"
                                    fieldType="text"
                                    fieldName="standName"
                                    fieldPlaceholder="Nombre de ID para la URL"
                                />
                                <hr />
                                <div className="generic-form__footer">
                                        <Button
                                            className="new-stand__button-add"
                                            type="submit"
                                            status="active"
                                        >
                                        <BsFillPlusCircleFill className="backend-imageButton__icon" />
                                            Agregar
                                        </Button>
                                    
                                </div>
                            </GenericForm>
                        </React.Fragment>
                    )}
                </Formik>
            </React.Fragment>
        );
};

export default NewStand;
