import React from "react";

import "./VideoStandCard.css";

const VideoStandCard = (props) => {
    return (
        <div
            className={`video-stand-card ${
                props.index === 0 ? "video-stand-card--firstchild" : ""
            } ${
                props.preview ? "video-stand-card--preview" : ""
            }`}
        >
            <div className="video-stand-card__title">
                <span
                    className="video-stand-card__title--green"
                    style={{ color: props.hexColor }}
                >
                    {props.firstTitle}{" "}
                </span>
            </div>
            <div className="video-stand-card__subtitle">
                <span
                    className="video-stand-card__title--regular"
                    style={{ color: props.cardTextColor }}
                >
                    {props.secondTitle}
                </span>
            </div>
            <iframe
                className="video-stand-card__iframe"
                title="card-video"
                src={`https://www.youtube.com/embed/${props.videoLink}`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        </div>
    );
};

export default VideoStandCard;
