import React, { useContext, useState, useEffect} from "react";
import {
    FormInputComponent,
} from "../../shared/components/FormElements/GenericForm";
import { Formik, Form } from "formik";

import "./ReducedStandCard.css";
import GenericModal from "../../shared/components/UIElements/GenericModal";
import Button from "../../shared/components/FormElements/Button";
import { BiLike } from "react-icons/bi";
import { AiFillLike } from "react-icons/ai"
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/AuthContext";
import { BsFillArrowRightCircleFill } from "react-icons/bs";

const ReducedStandCard = (props) => {
    const auth = useContext(AuthContext);
    const { sendRequest, clearError } = useHttpClient();
    const [modalShow, setModalShow] = useState(false);
    const [modalAnswerShow, setModalAnswerShow] = useState(false);
    const [filteredSelect, setFilteredSelect] = useState(false);


    const selected = props.fieldSelect ? props.fieldSelect
    : "";
    const selected1 = props.formInformation ? props.formInformation.fieldSelect
    : "";
    useEffect(() => {
        let selectArray = [];
        if (props.formInformation) {
            if (props.formInformation.fieldSelect){
                selectArray = props.formInformation.fieldSelect
                .split(";")
                .map((item) => ({ value: item, text: item }));

            }
            
        }
        setFilteredSelect(selectArray);
    }, [selected, selected1]);
    const handleAnswered = async () => {
        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_API_URL}/api/qrs/${props.standName}/${props._id}/cardformanswer`,
                "POST",
                undefined,
                {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + auth.token,
                }
            );

            if (responseData.interested === "not-exist") {
                setModalShow(true);
            }else{
                setModalAnswerShow(true);
            }
        } catch (err) {}
        return;
    };
    return (
        <React.Fragment>
        {props.formInformation && (
        <Formik
                initialValues={{
                    singleQuestionAnswer: null,
                }}
                onSubmit={async (values, {resetForm}) => {
                    clearError();
                    try {
                        const responseData = await sendRequest(
                            `${process.env.REACT_APP_API_URL}/api/qrs/${props.standName}/${props._id}/cardformsubmit`,
                            "POST",
                            JSON.stringify({
                                singleQuestionAnswer:
                                    values.singleQuestionAnswer,
                            }),
                            {
                                "Content-Type": "application/json",
                                Authorization: "Bearer " + auth.token,
                            }
                        );

                        if (responseData.interested === "sent") {
                            resetForm({values:''});
                            setModalShow(false);
                            setModalAnswerShow(true);
                        }
                    } catch (err) {}
                }}
            >
                {() => (
                    <React.Fragment>
                    <GenericModal
                        title={props.formInformation.fieldDescription}
                        nonError={true}
                        buttonless={true}
                        show={modalShow}
                        setModalShow={setModalShow}
                    >
                        <Form className="lottery-update-form">
                            <div>
                                {props.formInformation && props.formInformation.fieldAs === "text" && (
                                    <FormInputComponent
                                        fieldName={"singleQuestionAnswer"}
                                        fieldPlaceholder={
                                            'Escriba su respuesta...'
                                        }
                                        className={"pb-4"}
                                    />
                                )}

                                {props.formInformation && props.formInformation.fieldAs === "select" && (
                                    <FormInputComponent
                                        fieldAs={"select"}
                                        fieldType={"select"}
                                        fieldName={"singleQuestionAnswer"}
                                        fieldSelect={filteredSelect}
                                        className={"pb-4"}
                                    />
                                )}

                                <Button
                                    className="main-button--squared-bigger"
                                    type="submit"
                                    status="active"
                                >
                                    Enviar
                                </Button>
                            </div>
                        </Form>
                    </GenericModal>
                    <GenericModal
                        title="Muchas gracias por responder su pregunta"
                        nonError={true}
                        buttonless={true}
                        show={modalAnswerShow}
                        setModalShow={setModalAnswerShow}
                    >
                    </GenericModal>
                    </React.Fragment>
                )}
            </Formik>
            )}
        <div
            className={`reduced-stand-card reduced-stand-card--${
                props.preview ? "preview" : "grid"
            }`}
        >
            <div className="reduced-stand-card__top-container">
                {props.image && (
                    
                    <div className="reduced-stand-card__img-cropper">
                    <img
                        className={`reduced-stand-card__img${
                            props.preview ? "--preview" : ""
                        }`}
                        src={
                            props.imagePreview ||
                            `${process.env.REACT_APP_API_URL}/${props.image}`
                        }
                        alt=""
                    ></img>
                    </div>
                )}
            </div>
            <div className="reduced-stand-card__bottom-container">
                <div className="text-start">
                    {props.firstTitle && (
                        <div
                            className="reduced-stand-card__title"
                            style={{ color: props.hexColor }}
                        >
                            {props.firstTitle}
                        </div>
                    )}
                    {props.description && (
                        <div
                            className="reduced-stand-card__description"
                            style={{ color: props.cardTextColor }}
                        >
                            {props.description}
                        </div>
                    )}
                </div>
                <div>
                    {props.buttonType==="Action" && (
                    <Button
                        className="main-button--bigger main-button--80-width text-uppercase"
                        style={{
                            backgroundColor: props.hexColor,
                            color: props.buttonTextColor,
                        }}
                        onClick={() => handleAnswered()}
                    >
                        {props.buttonText}
                    </Button>
                    )}
                {props.buttonType==="Url" && props.buttonLink && (
                        <Button
                            className=""
                            type="submit"
                            style={{
                                backgroundColor: props.hexColor,
                                color: props.buttonTextColor,
                            }}
                            href={`//${props.buttonLink}`}
                            target="_blank"
                        > <BsFillArrowRightCircleFill className="backend-imageButton__icon" />
                            {props.buttonText}
                        </Button>
                    )}
                    {props.interestedButton && (
                        <div className="">
                            <button
                                onClick={props.handleInterested}
                                type="button"
                                className="main-button-interested"
                            >
                                <div
                                className="reduced-stand-card__interested-text"
                                style={{ color: props.hexColor }}
                                >
                                {props.interested && (
                                    <div className="reduced-stand-card__interested-icon--interested">
                                        <AiFillLike></AiFillLike>
                                    </div>
                                )}
                                {!props.interested && (
                                    <div className="reduced-stand-card__interested-icon--not-interested">
                                        <BiLike></BiLike>
                                    </div>
                                )}   
                                {props.interested ? "No me interesa" : "Me interesa"}
                                </div>
                            </button>
                           
                        </div>
                    )}
                </div>
            </div>
        </div>
        </React.Fragment>
    );
};

export default ReducedStandCard;
