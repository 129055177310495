import { React, useContext, useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import { IoCloseSharp } from "react-icons/io5";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import "./AuthModal.css";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/AuthContext";
import Button from "../../shared/components/FormElements/Button";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";


const LoginSchema = Yup.object().shape({
    email: Yup.string().email("Correo inválido").required("Requerido"),
    password: Yup.string()
        .min(6, "Al menos 6 caracteres")
        .max(24, "Demasiado larga")
        .required("Requerida"),
});

const RecoverySchema = Yup.object().shape({
    email: Yup.string().email("Correo inválido").required("Requerido"),
});

const SignupSchema = Yup.object().shape({
    firstName: Yup.string().max(24, "Demasiado largo").required("Requerido"),
    lastName: Yup.string().max(24, "Demasiado largo").required("Requerido"),
    email: Yup.string().email("Correo inválido").required("Requerido"),
    password: Yup.string()
        .min(6, "Al menos 6 caracteres")
        .max(24, "Demasiado larga")
        .required("Requerida"),
    confirmPassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Las contraseñas deben coincidir"
    ),
    //termsConditions: Yup.boolean().required(),
});

const AuthModal = (props) => {
    const [title, setTitle] = useState(null);
    const [email, setEmail] = useState(null);

    const modalTitler = (type) => {
        let modalTypes = [
            { type: "login", title: "Iniciá Sesión" },
            { type: "signup", title: "Registrate!" },
            { type: "passwordRecovery", title: "Recuperación de Contraseña" },
        ];
        let obj = modalTypes.find((o) => type === o.type);
        if (obj) {
            setTitle(obj.title);
        }
        return;
    };

    useEffect(() => {
        modalTitler(props.modalType);
    }, [props.modalType]);

    return (
        <Modal
            className="auth-modal"
            show={props.show}
            onHide={props.onHide}
            centered
        >
            <div className="auth-modal__header">
                <button
                    onClick={props.redirect || props.onHide}
                    className="auth-modal__close"
                >
                    <IoCloseSharp />
                </button>
                <div className="auth-modal__title">{title}</div>
            </div>
            {props.modalType === "signup" && (
                <Signup hide={props.onHide} setModalType={props.setModalType} />
            )}
            {props.modalType === "login" && (
                <Login
                    hide={props.onHide}
                    setModalType={props.setModalType}
                    setEmail={setEmail}
                />
            )}

            {props.modalType === "passwordRecovery" && (
                <PasswordRecovery
                    hide={props.onHide}
                    setModalType={props.setModalType}
                    email={email}
                />
            )}
        </Modal>
    );
};

const Login = (props) => {
    const auth = useContext(AuthContext);
    const { sendRequest, error, clearError } = useHttpClient();

    const hideHandler = () => {
        props.hide();
    };

    const forgotPasswordHandler = (email) => {
        props.setEmail(email);
        props.setModalType("passwordRecovery");
    };


    return (
        <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={LoginSchema}
            onSubmit={async (values, { resetForm }) => {
                clearError();
                try {
                    const responseData = await sendRequest(
                        `${process.env.REACT_APP_API_URL}/api/users/login`,
                        "POST",
                        JSON.stringify({
                            email: values.email,
                            password: values.password,
                            jwtgoogle: false,
                        }),
                        {
                            "Content-Type": "application/json",
                        }
                    );
                    auth.login(responseData);
                    hideHandler();
                } catch (err) {
                    resetForm();
                }
            }}
        >
            {({ isValid, dirty, values, isSubmitting }) => (
                <Form className="auth-form">
                <div className="auth-form__google">
                        <GoogleOAuthProvider clientId="888527542524-r4bs3sgt2bb0krdqsbqpbvbu6kn027pf.apps.googleusercontent.com" >
                            <GoogleLogin
                              onSuccess={async (credentialResponse)=> {
                                clearError();
                                let decoded = jwt_decode(credentialResponse.credential);
                                try {
                                    const responseData = await sendRequest(
                                        `${process.env.REACT_APP_API_URL}/api/users/login`,
                                        "POST",
                                        JSON.stringify({
                                            email: decoded.email,
                                            password: '',
                                            jwtgoogle: true,
                                            
                                        }),
                                        {
                                            "Content-Type": "application/json",
                                        }
                                    );
                                    console.log(responseData)
                                    auth.login(responseData);
                                    hideHandler();
                                } catch (err) {
                                    //resetForm();
                                }
                              }}
                              onError={() => {
                                console.log('Login Failed');
                              }}
                              />
                            </GoogleOAuthProvider>
                        </div>
                            {" o "}
                    {error && (
                        <div className="auth-form__api-error-placeholder">
                            <div className="auth-form__api-error">{error}</div>
                        </div>
                    )}
                    <div className="auth-form__content">
                        <div className="auth-form__input-block">
                            <div className="auth-form__error-placeholder">
                                <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="auth-form__validation-error"
                                />
                            </div>
                            <Field
                                type="email"
                                name="email"
                                placeholder="Email"
                                className="auth-form__form-input"
                            />
                        </div>

                        <div className="auth-form__input-block">
                            <div className="auth-form__error-placeholder">
                                <ErrorMessage
                                    name="password"
                                    component="div"
                                    className="auth-form__validation-error"
                                />
                            </div>
                            <Field
                                type="password"
                                name="password"
                                placeholder="Contraseña"
                                className="auth-form__form-input"
                            />
                        </div>
                    </div>

                    <button
                        className="auth-form__link"
                        type="button"
                        onClick={() => forgotPasswordHandler(values.email)}
                    >
                        ¿Olvidaste tu contraseña?
                    </button>

                    <div className="auth-form__footer">
                        <Button
                            className=""
                            status={dirty && isValid ? "active" : "inactive"}
                            disabled={isSubmitting}
                            type="submit"
                        >
                        <BsFillArrowRightCircleFill className="backend-imageButton__icon" />
                            Ingresar
                        </Button>
                        <div className="auth-form__link_black">
                            ¿No estás registrado?{" "}
                            <button
                                id="signup-switch"
                                className="auth-form__link"
                                type="button"
                                onClick={() => props.setModalType("signup")}
                            >
                                Registrate
                            </button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

const PasswordRecovery = (props) => {
    const { sendRequest, error, clearError } = useHttpClient();
    const hideHandler = () => {
        props.hide();
    };

    return (
        <Formik
            initialValues={{ email: props.email }}
            validationSchema={RecoverySchema}
            onSubmit={async (values, { resetForm }) => {
                clearError();
                try {
                    const responseDataMail = await sendRequest(
                        `${process.env.REACT_APP_API_URL}/api/mails/forgotpassword`,
                        "POST",
                        JSON.stringify({
                            email: values.email,
                        }),
                        {
                            "Content-Type": "application/json",
                        }
                    );
                    if (responseDataMail.approved === "sent") {
                        hideHandler();
                    }
                } catch (err) {}
            }}
        >
            {({ isValid, dirty, isSubmitting }) => (
                <Form className="auth-form">
                    {error && (
                        <div className="auth-form__api-error-placeholder">
                            <div className="auth-form__api-error">{error}</div>
                        </div>
                    )}
                    <div className="auth-form__content">
                        <div className="auth-form__input-block">
                            <div className="auth-form__error-placeholder">
                                <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="auth-form__validation-error"
                                />
                            </div>
                            <Field
                                type="email"
                                name="email"
                                placeholder="Email"
                                className="auth-form__form-input"
                            />
                        </div>
                    </div>

                    <div className="auth-form__footer">
                        <Button
                            className=""
                            status={dirty && isValid ? "active" : "inactive"}
                            type="submit"
                        >
                          <BsFillArrowRightCircleFill className="backend-imageButton__icon" />
                            Enviar
                        </Button>
                        <div>
                            <button
                                id="signup-switch"
                                className="auth-form__link"
                                type="button"
                                onClick={() => props.setModalType("login")}
                            >
                                Volver a Login
                            </button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

const Signup = (props) => {
    const auth = useContext(AuthContext);
    const { sendRequest, error, clearError } = useHttpClient();

    const hideHandler = () => {
        props.hide();
    };

    const ContextListener = () => {
        const { values, setFieldValue } = useFormikContext();

        useEffect(() => {
            if (values.guest) {
                setFieldValue("password", "123456");
                setFieldValue("confirmPassword", "123456");
            } else {
                setFieldValue("password", "");
                setFieldValue("confirmPassword", "");
            }
        }, [values.guest, setFieldValue]);
        return null;
    };

    return (
        <Formik
            initialValues={{
                guest: true,
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                confirmPassword: "",
                termsConditions: true,
            }}
            validationSchema={SignupSchema}
            onSubmit={async (values, { setFieldValue, resetForm }) => {
                clearError();
                try {
                    const responseData = await sendRequest(
                        `${process.env.REACT_APP_API_URL}/api/users/signup`,
                        "POST",
                        JSON.stringify({
                            guest: values.guest,
                            firstName: values.firstName,
                            lastName: values.lastName,
                            email: values.email,
                            password: values.password,
                            termsConditions: true,
                        }),
                        {
                            "Content-Type": "application/json",
                        }
                    );
                    auth.login(responseData);
                    hideHandler();
                } catch (err) {}
            }}
        >
            {({ isValid, dirty, values, setFieldValue }) => (
                    <Form className="auth-form">
                    <div className="auth-form__google">
                        <GoogleOAuthProvider clientId="268998851668-cm1aod97i9hoapm5p32avn48juer9k7i.apps.googleusercontent.com" >
                            <GoogleLogin
                              onSuccess={async credentialResponse => {
                                clearError();
                                let decoded = jwt_decode(credentialResponse.credential);
                                try {
                                    const responseData = await sendRequest(
                                        `${process.env.REACT_APP_API_URL}/api/users/signup`,
                                        "POST",
                                        JSON.stringify({
                                            guest: true,
                                            firstName: decoded.given_name,
                                            lastName: decoded.family_name,
                                            email: decoded.email,
                                            password: values.password,
                                            termsConditions: true,
                                            
                                        }),
                                        {
                                            "Content-Type": "application/json",
                                        }
                                    );
                                    auth.login(responseData);
                                    hideHandler();
                                } catch (err) {}
                                //llamar al endpoint de create_user y listo
                              }}
                              onError={() => {
                                console.log('Login Failed');
                              }}
                              />
                            </GoogleOAuthProvider>
                        </div>
                            {" O "}
                        {error && (
                            <div className="auth-form__api-error-placeholder">
                                <div className="auth-form__api-error">
                                    {error}
                                </div>
                            </div>
                        )}
                        <div className="auth-form__content">
                            <div className="auth-form__input-block auth-form__guest-checkbox">
                                <Field type="checkbox" name="guest" /> Ingresar
                                como invitado{" "}
                                <span className="auth-form__link">
                                    (crea una contraseña por defecto)
                                </span>
                            </div>

                            <div className="auth-form__input-block auth-form__input-block--grid">
                                <div className="auth-form__input-block auth-form__input-block--column">
                                    <div className="auth-form__error-placeholder auth-form__error-placeholder--column">
                                        <ErrorMessage
                                            name="firstName"
                                            component="div"
                                            className="auth-form__validation-error"
                                        />
                                    </div>
                                    <Field
                                        type="firstName"
                                        name="firstName"
                                        placeholder="Nombre"
                                        className="auth-form__form-input auth-form__form-input--inline"
                                    />
                                </div>
                                <div className="auth-form__input-block auth-form__input-block--column">
                                    <div className="auth-form__error-placeholder auth-form__error-placeholder--column">
                                        <ErrorMessage
                                            name="lastName"
                                            component="div"
                                            className="auth-form__validation-error"
                                        />
                                    </div>
                                    <Field
                                        type="lastName"
                                        name="lastName"
                                        placeholder="Apellido"
                                        className="auth-form__form-input auth-form__form-input--inline"
                                    />
                                </div>
                            </div>

                            <div className="auth-form__input-block">
                                <div className="auth-form__error-placeholder">
                                    <ErrorMessage
                                        name="email"
                                        component="div"
                                        className="auth-form__validation-error"
                                    />
                                </div>
                                <Field
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    className="auth-form__form-input"
                                />
                            </div>
                            {!values.guest && (
                                <div>
                                    <div className="auth-form__input-block">
                                        <div className="auth-form__error-placeholder">
                                            <ErrorMessage
                                                name="password"
                                                component="div"
                                                className="auth-form__validation-error"
                                            />
                                        </div>
                                        <Field
                                            type="password"
                                            name="password"
                                            placeholder="Contraseña"
                                            className="auth-form__form-input"
                                        />
                                    </div>

                                    <div className="auth-form__input-block">
                                        <div className="auth-form__error-placeholder">
                                            <ErrorMessage
                                                name="confirmPassword"
                                                component="div"
                                                className="auth-form__validation-error"
                                            />
                                        </div>
                                        <Field
                                            type="password"
                                            name="confirmPassword"
                                            placeholder="Confirmar Contraseña"
                                            className="auth-form__form-input"
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="auth-form__input-block mt-3">
                                {/*<Field type="checkbox" name="termsConditions" />*/}{" "}
                                Al registrarse ud acepta todos los{" "}
                                <button className="auth-form__link">
                                    términos y condiciones
                                </button>
                            </div>
                        </div>

                        <div className="auth-form__footer">
                            <Button
                                className=""
                                status={
                                    dirty && isValid ? "active" : "inactive"
                                }
                                type="submit"
                            >
                            <BsFillArrowRightCircleFill className="backend-imageButton__icon" />
                                Registrate
                            </Button>
                            <div>
                                ¿Ya tenés una cuenta en Fullexy?{" "}
                                <button
                                    id="signup-switch"
                                    className="auth-form__link"
                                    type="button"
                                    onClick={() => props.setModalType("login")}
                                >
                                    Iniciá sesión
                                </button>
                            </div>
                        </div>
                        <ContextListener />
                    </Form>
            )}
        </Formik>
    );
};

export default AuthModal;
