import React, { useState, useContext, useEffect, useRef  } from "react";

import CuponsList from "../components/CuponsList";
import { useParams } from "react-router-dom";
import BannerStands from '../../stands/assets/Banner5.png';
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/AuthContext";
import Button from "../../../shared/components/FormElements/Button";
import PaginationNew from "../../../shared/components/UIElements/PaginationNew";
import { BsFillArrowLeftCircleFill, BsFillSaveFill } from "react-icons/bs";
import "./Cupons.css";

const Cupons = () => {
    const inputSearchRef = useRef(null);
    const auth = useContext(AuthContext);
    const standName = useParams().standName;
    const { isLoading, sendRequest } = useHttpClient();
    const [loadedCupons, setLoadedCupons] = useState();
    const [cuponsrefresh, setCuponsRefresh] = useState(false);
    const [userSearch, setUserSearch] = useState("");
    let PageSize = 30;

    const [currentPage, setCurrentPage] = useState(1);
    const [totalElements, setTotalElements] = useState(0);
  
    const handleShowSignup = () => {
        const link = document.createElement("a");
        link.href = `${process.env.REACT_APP_API_URL}/uploads/images/${standName}cupones.xlsx`;
        //link.setAttribute('download',`${stand._id}.xlsx`,);
        link.download = `${standName}cupones.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleSearch = () => {
        setUserSearch(inputSearchRef.current.value);
    };

    const handleOnChange = (event) => {

        if(event.target.value === ''){
            setUserSearch('');
        }

    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          // 👇 Get input value
          setUserSearch(event.target.value);
        }
      };

    
        const fetchCupons = async (page) => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_API_URL}/api/cupons/${standName}?page=${page}&limit=${PageSize}&filter=${userSearch}`,
                    "GET",
                    null,
                    { Authorization: "Bearer " + auth.token }
                );
                setLoadedCupons(responseData.cupons);
                setTotalElements(responseData.paginate.totalElements);
                setCuponsRefresh(false);
            } catch (err) {}
        };
    useEffect(() => {
        fetchCupons(currentPage);
    }, [currentPage,sendRequest, standName, cuponsrefresh, auth.token,userSearch]);

    return (
        <React.Fragment>
            <img src={BannerStands} width="100%" alt="" />
            <div className="generic-form__button-block"> 
                                <Button
                                    to="/backend/qrs"
                                    className="stands-list__back-button"
                                >
                                    <BsFillArrowLeftCircleFill className="standlist-imageButton__iconblack" />
                                    Atrás
                                </Button>
            </div>
            <div className="admin-users-list__header">
                
                <div className="search-bar__container_admin-users">
                        <input
                            id="Search"
                            ref={inputSearchRef}
                            className="search-bar_admin-users"
                            type="search"
                            placeholder="Buscar por cupon ..."
                            onChange={handleOnChange}
                            onKeyDown={handleKeyDown}
                        />
                        <Button
                         onClick={handleSearch}
                        className="search-bar-button"
                        >
                        Buscar
                        </Button>
                </div>
                <div className="users__container">
                    <Button status="active" onClick={handleShowSignup}>
                        <BsFillSaveFill className="backend-imageButton__icon" />
                        Descargar Analíticas
                    </Button>
                </div>
            </div>
       
            {!isLoading && loadedCupons && (
                <div>
                        <PaginationNew
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={totalElements}
                            pageSize={PageSize}
                            onPageChange={page => setCurrentPage(page)}
                            />
                             <CuponsList
                                    cuponslist={loadedCupons}
                                    stand={standName}
                                    refresh={setCuponsRefresh}
                                />
                        </div>
                
            )}
        
        </React.Fragment>
    );
};

export default Cupons;
