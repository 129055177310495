import React , { useContext , useState, useEffect } from "react";
import { AuthContext } from "../../shared/context/AuthContext";
import { useHttpClient } from "../../shared/hooks/http-hook";
import HomePageForm from "../components/HomePageForm";
import HomePageFormText from "../components/HomePageFormText";
import ComoFunciona from "../components/ComoFunciona.jsx";
import BannerVideo from "../../shared/components/UIElements/BannerVideo";
import Button from "../../shared/components/FormElements/Button";
import "./Home.css";

import bannerHome from "../assets/banner-default.jpg";

const Home = (props) => {
    const auth = useContext(AuthContext);
    const { sendRequest } = useHttpClient();
    const [loggedUser, setLoggedUser] = useState(null);

    useEffect(() => {
        setLoggedUser(auth.loggedUserData);
    }, [auth.loggedUserData]);

    useEffect(() => {
        setLoggedUser(auth.loggedUserData);
    }, [sendRequest, auth.loggedUserData]);
    return (
        <React.Fragment>
            <img
                src={bannerHome}
                alt=""
                className="stand-banner"
            />
            {auth.isLoggedIn && loggedUser &&
            (loggedUser.userType !== "user") && (
                <React.Fragment>
                <div className="button-banner">
                    <Button
                        className="main-button--squared-bigger main-button--100-width"
                        status="active"
                        type="submit"
                        href="/backend"
                    
                    >
                        Creá tu QR
                    </Button>
                </div>
                </React.Fragment>
            )}

            <ComoFunciona />

        </React.Fragment>
        
    );
};

export default Home;
