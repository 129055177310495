import React, { useContext, useState, useEffect} from "react";
import {
    FormInputComponent,
} from "../../shared/components/FormElements/GenericForm";
import { Formik, Form } from "formik";

import "./EcommerceStandCard.css";

import Button from "../../shared/components/FormElements/Button";
import Badge from "../../shared/components/UIElements/Badge.jsx";
import GenericModal from "../../shared/components/UIElements/GenericModal";
import { BiLike } from "react-icons/bi";
import { AiFillLike } from "react-icons/ai"
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/AuthContext";
import { BsFillArrowRightCircleFill } from "react-icons/bs";

const EcommerceStandCard = (props) => {
    const auth = useContext(AuthContext);
    const { sendRequest, clearError } = useHttpClient();
    const [modalShow, setModalShow] = useState(false);
    const [modalAnswerShow, setModalAnswerShow] = useState(false);
    const [filteredSelect, setFilteredSelect] = useState(false);
    const [cupon, setCupon] = useState("");

    const selected = props.fieldSelect ? props.fieldSelect
    : "";
    const selected1 = props.formInformation ? props.formInformation.fieldSelect
    : "";
    useEffect(() => {
        let selectArray = [];
        if (props.formInformation) {
            if (props.formInformation.fieldSelect){
                selectArray = props.formInformation.fieldSelect
                .split(";")
                .map((item) => ({ value: item, text: item }));

            }
            
        }
        setFilteredSelect(selectArray);
    }, [selected, selected1]);
    const handleAnswered = async () => {
        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_API_URL}/api/qrs/${props.standName}/${props._id}/cardformanswer`,
                "POST",
                undefined,
                {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + auth.token,
                }
            );

            if (responseData.interested === "not-exist") {
                setModalShow(true);
            }else{
                setCupon(responseData.cupon);
                
                setModalAnswerShow(true);
            }
        } catch (err) {}
        return;
    };
    return (
        <React.Fragment>
        {props.formInformation && (
        <Formik
                initialValues={{
                    cuponQuestionAnswer: null,
                }}
                onSubmit={async (values, {resetForm}) => {
                    clearError();
                    try {
                        const responseData = await sendRequest(
                            `${process.env.REACT_APP_API_URL}/api/qrs/${props.standName}/${props._id}/cardformsubmit`,
                            "POST",
                            JSON.stringify({
                                cuponQuestionAnswer:
                                    values.cuponQuestionAnswer,
                            }),
                            {
                                "Content-Type": "application/json",
                                Authorization: "Bearer " + auth.token,
                            }
                        );

                        if (responseData.interested === "sent") {
                            resetForm({values:''});
                            setModalShow(false);
                            setCupon(responseData.cupon);
                            setModalAnswerShow(true);
                        }
                    } catch (err) {}
                }}
            >
                {() => (
                    <React.Fragment>
                    <GenericModal
                        title={props.formInformation.fieldDescription}
                        nonError={true}
                        buttonless={true}
                        show={modalShow}
                        setModalShow={setModalShow}
                    >
                        <Form className="lottery-update-form">
                            <div>
                                {props.formInformation && props.formInformation.fieldAs === "text" && (
                                    <FormInputComponent
                                        fieldName={"cuponQuestionAnswer"}
                                        fieldPlaceholder={
                                            'Escriba su respuesta...'
                                        }
                                        className={"pb-4"}
                                    />
                                )}

                                {props.formInformation && props.formInformation.fieldAs === "select" && (
                                    <FormInputComponent
                                        fieldAs={"select"}
                                        fieldType={"select"}
                                        fieldName={"cuponQuestionAnswer"}
                                        fieldSelect={filteredSelect}
                                        className={"pb-4"}
                                    />
                                )}

                                <Button
                                    className=""
                                    type="submit"
                                    status="active"
                                >
                                  <BsFillArrowRightCircleFill className="backend-imageButton__icon" />
                                    Enviar
                                </Button>
                            </div>
                        </Form>
                    </GenericModal>
                    <GenericModal
                        title="Canjee su cupon"
                        nonError={true}
                        buttonless={true}
                        show={modalAnswerShow}
                        setModalShow={setModalAnswerShow}
                    >
                    <div className="ecommerce-stand-card-cupon__container">
                        <div className="ecommerce-stand-card-cupon__description">
                            <h4>
                                Su cupon es {cupon}
                            </h4>
                        </div>
                    </div>
                    </GenericModal>
                    </React.Fragment>
                )}
            </Formik>
            )}
        <div
            className={`ecommerce-stand-card ecommerce-stand-card--${
                props.preview ? "preview" : "grid"
            }`}
        >
        
            <div className="ecommerce-stand-card__top-container">
                <Badge status="" className="ecommerce-card__badge" style={{ backgroundColor: props.hexColor,color: props.buttonTextColor, }}>
                    {" "}
                        
                        <span className="ecommerce-badge-text">{100 - Math.floor(
                            (props.pricediscount /
                                props.price) *
                                100
                        )}
                        %</span> OFF
                </Badge>
                
            </div>
            {props.image && (
                    <img 
                        className={`ecommerce-stand-card__img${
                            props.preview ? "--preview" : ""
                        }`}
                        src={
                            props.imagePreview ||
                            `${process.env.REACT_APP_API_URL}/${props.image}`
                        }
                        alt=""
                    ></img>

                )}
            <div className="ecommerce-stand-card__bottom-container">
           
                <div>
                    {props.description && (
                        <div
                            className="ecommerce-stand-card__description"
                            style={{ color: props.cardTextColor }}
                        >
                            {props.description}
                        </div>
                    )}
                    {props.price && (
                        <div
                            className="ecommerce-stand-card__price"
                            style={{ color: props.cardTextColor }}
                        >
                            ${props.price}
                        </div>
                    )}
                    {props.pricediscount && (
                        <div
                            className="ecommerce-stand-card__pricediscount"
                            style={{ color: props.hexColor }}
                        >
                            ${props.pricediscount}
                        </div>
                    )}
                    {props.buttonType==="Action" && (
                    <Button
                        className=""
                        style={{
                            backgroundColor: props.hexColor,
                            color: props.buttonTextColor,
                        }}
                        onClick={() => handleAnswered()}
                    >
                    <BsFillArrowRightCircleFill className="backend-imageButton__icon" />
                        {props.buttonText}
                    </Button>
                )}
                {props.buttonType==="Url" && props.buttonLink && (
                        <Button
                            className=""
                            type="submit"
                            style={{
                                backgroundColor: props.hexColor,
                                color: props.buttonTextColor,
                            }}
                            href={`//${props.buttonLink}`}
                            target="_blank"
                        >  <BsFillArrowRightCircleFill className="backend-imageButton__icon" />
                            {props.buttonText}
                        </Button>
                    )}
                    {props.interestedButton && (
                        <div className="">
                            <button
                                onClick={props.handleInterested}
                                type="button"
                                className="main-button-interested"
                            >
                                <div
                                className="reduced-stand-card__interested-text"
                                style={{ color: props.hexColor }}
                                >
                                {props.interested && (
                                    <div className="reduced-stand-card__interested-icon--interested">
                                        <AiFillLike></AiFillLike>
                                    </div>
                                )}
                                {!props.interested && (
                                    <div className="reduced-stand-card__interested-icon--not-interested">
                                        <BiLike></BiLike>
                                    </div>
                                )}   
                                {props.interested ? "No me interesa" : "Me interesa"}
                                </div>
                            </button>
                           
                        </div>
                    )}
                </div>
            </div>
        </div>
        </React.Fragment>
    );
};

export default EcommerceStandCard;
