import React, { useContext, useState, useEffect, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";

import { AuthContext } from "../../context/AuthContext";
import { StandNavContext } from "../../context/StandNavContext";

import { useHttpClient } from "../../hooks/http-hook";

import "./MainNavigation.css";
import Avatar from "../UIElements/Avatar";

import AuthModal from "../../../user/components/AuthModal";
import UserUpdateModal from "../../../user/components/UserUpdateModal";

import defaultLogo from "../../assets/logo-small.png";

import tinycolor from "tinycolor2";

const MainNavigation = (props) => {
    const auth = useContext(AuthContext);
    const { standNavContext } = useContext(StandNavContext);

    const { sendRequest } = useHttpClient();

    const [modalType, setModalType] = useState(null);
    const [showAuth, setShowAuth] = useState(false);
    const [showUserUpdate, setShowUserUpdate] = useState(false);
    const [loggedUser, setLoggedUser] = useState(null);

    const [variableNavProperties, setVariableNavProperties] = useState([
        null,
        null,
        defaultLogo,
    ]);

    const handleCloseAuth = () => setShowAuth(false);

    const handleShowAuth = (type) => {
        setModalType(type);
        setShowAuth(true);
    }

    const handleUserUpdate = useCallback(
        () => setShowUserUpdate(!showUserUpdate),
        [showUserUpdate]
    );

    useEffect(() => {
        setLoggedUser(auth.loggedUserData);
    }, [sendRequest, auth.loggedUserData]);

    const location = useLocation();

    useEffect(() => {
        if (
            window.location.pathname.includes("/backend/qrs") &&
            props.preview
        ) {
            setVariableNavProperties([
                props.navColor,
                props.navButtonsColor,
                props.navIconPreview || defaultLogo,
            ]);
        } else if (
            window.location.pathname.includes("/qrs/") &&
            !window.location.pathname.includes("/backend/qrs") &&
            standNavContext
        ) {
            let variableNavIcon;
            variableNavIcon =
                standNavContext.navIcon === null
                    ? defaultLogo
                    : `${process.env.REACT_APP_API_URL}/${standNavContext.navIcon}`;
            setVariableNavProperties([
                standNavContext.navColor,
                standNavContext.navButtonsColor,
                variableNavIcon,
            ]);
        } else {
            setVariableNavProperties([null, null, defaultLogo]);
        }
        // eslint-disable-next-line
    }, [location, standNavContext]);

    return (
        <React.Fragment>
            <style>
                {`
                  .navbar-light .navbar-toggler-icon {
                    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30' %3E%3Cpath stroke='${
                        tinycolor(variableNavProperties[1]).toRgbString() ||
                        "rgb(253,200,14)"
                    }' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22' /%3E%3C/svg%3E");
                  }
                  .navbar__center-content, .main-navbar {
                    background: ${variableNavProperties[0]};
                  }
                  .nav-link {
                      color: ${variableNavProperties[1]} !important;
                  }
                  .nav__username {
                    color: ${variableNavProperties[1]} !important;
                }
                `}
            </style>
                <AuthModal
                    show={showAuth}
                    onHide={handleCloseAuth}
                    setModalType={setModalType}
                    modalType={modalType}
                />
            <UserUpdateModal
                {...loggedUser}
                show={showUserUpdate}
                onHide={handleUserUpdate}
            />
            <Navbar
                collapseOnSelect
                className="main-navbar"
                bg="default"
                expand={`${props.preview ? "no" : "lg"}`}
            >
                <Navbar.Brand as={Link} to="/" className="navbar__logo">
                    <img src={variableNavProperties[2]} alt="SimpleStand" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />

                <Navbar.Collapse
                    id="navbarScroll"
                    className="navbar__center-content"
                >
                    <Nav></Nav>
                    {!auth.isLoggedIn && (
                        <Nav>
                            <Nav.Link
                                onClick={() => handleShowAuth("signup")}
                                className="navbar__link navbar__link--small"
                                eventKey="5"
                            >
                                Creá tu Cuenta
                            </Nav.Link>
                            <Nav.Link
                                onClick={() => handleShowAuth("login")}
                                className="navbar__link navbar__link--small"
                                eventKey="6"
                            >
                                Ingresá
                            </Nav.Link>
                        </Nav>
                    )}
                    {auth.isLoggedIn && (
                        <Nav>
                            <Nav.Link
                                onClick={handleUserUpdate}
                                className="navbar__dropdown-link--grey"
                                eventKey="10"
                            >
                                Mis Datos
                            </Nav.Link>
                            {loggedUser && loggedUser.userType !== "user" && (
                                <Nav.Link
                                    as={Link}
                                    className="navbar__dropdown-link--grey"
                                    eventKey="15"
                                    to="/backend"
                                >
                                    Backend
                                </Nav.Link>
                            )}
                            {loggedUser && loggedUser.userType === "user" && (
                                <Nav.Link
                                    as={Link}
                                    className="navbar__dropdown-link--grey"
                                    eventKey="15"
                                    to="/visited"
                                >
                                    Stands Visitados
                                </Nav.Link>
                            )}

                            <Nav.Link
                                className="navbar__dropdown-link--grey"
                                onClick={auth.logout}
                                as={Link}
                                to="/"
                                eventKey="7"
                            >
                                Cerrar Sesión
                            </Nav.Link>
                            <div className="navbar__profile-container">
                                <Avatar
                                    image={
                                        loggedUser &&
                                        `${process.env.REACT_APP_API_URL}/${loggedUser.image}`
                                    }
                                    alt="default avatar"
                                    className="avatar--bordered"
                                    style={{
                                        marginRight: "8px",
                                        width: "24px",
                                        height: "24px",
                                    }}
                                ></Avatar>
                                <div className="nav__username">
                                    {loggedUser && loggedUser.firstName}
                                </div>
                            </div>
                        </Nav>
                    )}
                </Navbar.Collapse>
            </Navbar>
        </React.Fragment>
    );
};

export default MainNavigation;
